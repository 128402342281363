import React from 'react'
import HeyJudeUtils from '../../Utils/HeyJudeUtils';
import InputText from '../../Components/InputText/index';
import style from './signIn.module.scss';
import UserControl from '../../Control/UserControl';
import UserModel from '../../Model/UserModel';
import ConnectionType from '../../Connect/ConnectionType';

import Button from '../../Components/Button/index';
import Picture from '../../Components/Picture/index';

import theme from '../../Theme/theme.module.scss';
import Axios from 'axios';

const logo = require('../../Assets/Img/logo.jpg')
const share = require('../../Assets/Img/share.png')

class SignIn extends React.Component {

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    this.formData = {};
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
  }

  componentDidMount(){
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let token = params.get('token');
			let register = params.get('register');
			console.log (register)

			if (register == 1) {
				this.onSignUp();
			}

      if(token !== null){

        UserControl.getInstance().tokenSignIn({

          token: token

        }).then(response => {

          window.history.pushState({}, document.title, "/");

        })

      }

    }

  onInputChange(e){
    this.formData[e.target.id] = e.target.value;
  }

  onSubmit(){
    UserControl.getInstance().signIn({
      password: this.fldPassword.getCurrentValue(),
      username: this.fldUsername.getCurrentValue()
    });

    //UserControl.getInstance().signIn({ password: 'zzzzzz', username: 'byrontudhope@gmail.com' })
    //UserControl.getInstance().signIn({ password: 'Pankre@s12', username: 'gerhardt@tct.digital' })
  }

  onForgotPassword(){
    UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_FORGOT_PASSWORD,{show:true})
  }
  onSignUp(){
    UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_SIGN_UP,{signUp:true})
  }
  onTokenSignIn(token){
    UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_TOKEN_SIGN_IN,{token:token})
  }
  getToken(){
    Axios({
      method: 'POST',
      url: 'https://test.oppwa.com/v1/checkouts',
      headers: {
        'Authorization':'Bearer OGE4Mjk0MTc0ZTczNWQwYzAxNGU3OGNmMjY2YjE3OTR8cXl5ZkhDTjgzZQ==',
      },
      data: {
        entityId:'8a8294174e735d0c014e78cf26461790',
        amount:'92.00',
        currency:'ZAR',
        createRegistration:'true'
      }
    })
  }

  render() {
    return (
    	<div className="login-bg">
      <div className={`wrapper wrapper_page wrapper-sign ${theme[this.props.theme]}`}>
        <div className={`wrapper wrapper_flex-center min-h-100vh pt-30 pb-30`}>
          <div className={`${style.SignInForm}`} key="SignInInner">
            <center><img src="https://www.heyjudeapp.com/img/logo-nav-light.svg" alt="Logo" width="200"/></center>
            <InputText
              theme={this.props.theme}
              setReference={(ref)=>this.fldUsername=ref}
              defaultValue=""
              id="username"
              placeholder="Email"
              type="text"
              className="mt-30 min-w-300"
            />
            <InputText
              theme={this.props.theme}
              setReference={(ref)=>this.fldPassword=ref}
              defaultValue=""
              id="password"
              placeholder="Password"
              type="password"
              className="mt-15 min-w-300"
            />
            <center><Button onClick={this.onSubmit} text="Login" theme={this.props.theme} className="uppercase mt-20"></Button></center>
            <div className="wrapper wrapper_flex-center mt-30">
              <Button onClick={this.onForgotPassword} textonly text="Forgot your password?" className="fz-14 ForgotPasswordButton"></Button>
            </div>
						<center><Button onClick={this.onSignUp} text="Register now" className="fz-14 ForgotPasswordButton mt-20"></Button></center>
						 <center><p className="mt-20 fz-10">Install this app?</p>
             <table><tr><td><span className="fz-10">Tap</span></td><td><img src="https://prioritypet.co.uk/share.svg" alt="" height="20"/></td><td> <span className="fz-10">then <strong>'Add to home screen'</strong></span></td></tr></table></center>
            {/* <Button onClick={this.getToken} text="token" theme={this.props.theme} className="uppercase mt-50"></Button> */}
          </div>
        </div>
      </div>
      </div>

    )
  }
}

export default SignIn;
