import React from 'react'
import style from './tokenSignIn.module.scss';
import Picture from '../../Components/Picture/index';

import theme from '../../Theme/theme.module.scss';
import Axios from 'axios';

const logo = require('../../Assets/Img/appIcon.png')

class SignIn extends React.Component {

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
  }

  componentDidMount(){
    // this.onSubmit();
    // UserControl.getInstance().signIn({ password: 'password', username: 'm@rcus.kiwi' })
    // UserControl.getInstance().signIn({ password: '111', username: 'zz@zz.zz' })
  }

  getToken(){
    Axios({
      method: 'POST',
      url: 'https://test.oppwa.com/v1/checkouts',
      headers: {
        'Authorization':'Bearer OGE4Mjk0MTc0ZTczNWQwYzAxNGU3OGNmMjY2YjE3OTR8cXl5ZkhDTjgzZQ==',
      },
      data: {
        entityId:'8a8294174e735d0c014e78cf26461790',
        amount:'92.00',
        currency:'ZAR',
        createRegistration:'true'
      }
    })
  }

  render() {
    return (
      <div className={`wrapper wrapper_page wrapper-sign ${theme[this.props.theme]}`}>
        <div className={`wrapper wrapper_flex-center min-h-100vh pt-30 pb-30`}>
          <div className={`${style.SignInForm}`} key="SignInInner">
            <Picture src={logo} alt=""></Picture>
            <br/>
            <img src={require('../../Assets/Img/logoTagline.svg')} />
          </div>
        </div>
      </div>

    )
  }
}

export default SignIn;
