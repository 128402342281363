import React from 'react';
import HeyJudeUtils from '../../../../Utils/HeyJudeUtils';
import MediaControl from '../../../../Control/MediaControl';
import MessageControl from '../../../../Control/MessageControl';

class MessageTypeAudio extends React.PureComponent{

  constructor(props){
    super(props)

    this.audioRef = React.createRef();

    this.state = {
      resendOnSend:false,
      resendOnUpload:false,
      src: this.props.data.pending
            ? this.props.data.pendingURL
            : undefined
    }

    this.renderResend = this.renderResend.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.downloadConvertedToURL = this.downloadConvertedToURL.bind(this);
  }

  componentDidMount(){
    if(this.props.data.pending){
      this.uploadAttachment();
      return
    }
    this.downloadAttachment();
  }
  
  async uploadAttachment(){
    var uploadResponse = await MessageControl.getInstance().uploadAttachment(this.props.data.pendingFile)
    this.setState({resendOnUpload:!uploadResponse.result})

    if(!uploadResponse.result){
      return
    }

    this.attachmentId = uploadResponse.data;
    this.sendMessage();
  }

  async sendMessage(){
    var messageResponse = await MessageControl.getInstance().sendMessage({
      type:'audio',
      taskId:this.props.data.taskId,
      isFeedback:this.props.data.isFeedback,
      attachmentId:this.attachmentId
    })

    this.setState({resendOnSend:!messageResponse})
  }

  resendMessage(){
    this.setState({
      resendOnSend:false,
      resendOnUpload:false,
    })
    this.sendMessage();
  }

  async downloadAttachment(){
    if(this.props.data.pending){
      return;
    }

    var response = await HeyJudeUtils.JudeManager().Attachments.download(this.props.data.attachmentId);
    MediaControl.getInstance().convertToBase64(response,this.downloadConvertedToURL);
  }

  async downloadConvertedToURL(result){
    this.setState({src : result})
  }

  renderResend(){
    if(!this.state.resendOnSend && !this.state.resendOnUpload){
      return null;
    }
    
    return(
      <div>
        <button onClick={this.resendMessage}>
          Error, press to resend
        </button>
      </div>
    )
  }

  render(){
    return(
          <audio controls className="AudioPlayer" src={this.state.src} ref={this.audioRef}>
                Your browser does not support the audio element.
          </audio>
    )
  }
}

export default MessageTypeAudio;