import React from 'react'
import SubscriptionControl from '../../../Control/SubscriptionControl'
import Utils from '../../../Utils/Utils'
import Button from '../../Button'

import './Subscriptions.scss'

export default class SubscriptionItem extends React.PureComponent {

  constructor(props) {
    super(props)
    this.item = this.props.item
  }

  onSubscribe() {
    if(this.props.onSubscribe){
      this.props.onSubscribe(this.item.id)
    }
  }

  onCheckPressed(e) {
    SubscriptionControl.getInstance().setAutoRenew(e.target.checked)
  }

  renderOptions() {

    if(this.props.status && this.item.id == this.props.status.currentSubscriptionOption) {
      return (
        <div>
          <div className="SubscriptionNextText">{this.props.status.expiryText}</div>
          <div className="SubscriptionNextTextToo">Renew my subscription automatically &nbsp;
            <input className="SubscriptionCheck" label type="checkbox" checked={this.props.status.autoRenew || false} onChange={(e) => this.onCheckPressed(e)}/>
          </div>
        </div>
      )
    } else if(this.props.status && this.item.id === this.props.status.chosenSubscriptionOption) {
      return (
        <div>
          <div className="SubscriptionNextText">Once your current subscription expires, this is your next subscription option.</div>
          <Button onClick={() => this.onSubscribe()} textonly text="Subscribe" className="color-white fz-14" theme={{textAlign:" right"}}/>
        </div>
      )
    } else {
      return <Button onClick={() => this.onSubscribe()} textonly text="Subscribe" className="color-white fz-14" theme={{textAlign:" right"}}/>
    }
  }

  render() {
    if(!this.item.available) {
      return null
    }

    return(
      <div className="SubscriptionItem">
        <div className="SubscriptionItemTitle">{this.item.name}</div>
        <div className="SubscriptionItemPrice">{Utils.convertCurrency(this.item.currency)}{this.item.price}</div>
        <div className="SubscriptionItemText">{this.item.description}</div>
        {this.renderOptions()}
      </div>
    )
  }
}

/*
available: true
currency: "ZAR"
description: "Sign up for 12 months of Hey Jude and get two months on us."
duration: "12 months"
id: 42
isPro: null
name: "12-Month Subscription"
price: "999.00"
proHours: "0.00"
type: "time"
*/
