import React from 'react';
import style from './picture.module.scss';

class Picture extends React.PureComponent{

    static defaultProps = {
        theme: 'default'
    };

    constructor(props){
        super(props);
    }

    render(){
        return(
            <img
                className={`
                    ${style.picture}
                    ${(this.props.logo)? style.logo : ''}
                    ${(this.props.user)? style.user : ''}
                `}
                src={this.props.src}
                alt={this.props.alt}>
            </img>
        );
    }
}

export default Picture;
