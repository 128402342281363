import React from 'react';

import TitleBar from '../../Components/Screen/TitleBar/index';
import TasksView from '../../Components/Screen/TasksView';
import NewTaskInput from '../../Components/Screen/NewTaskInput';
import Subscriptions from '../../Components/Screen/Subscriptions';

import SocketControl from '../../Control/SocketControl';
import style from './mainApp.module.scss';
import theme from '../../Theme/theme.module.scss';
import AlertMessage from '../../Components/Overlay/AlertMessage';

class MainApp extends React.PureComponent{
  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    SocketControl.getInstance().setNotify(true)
    SocketControl.getInstance().connect();
    this.initNotifs = this.initNotifs.bind(this)

    // this.onPageFocus = this.onPageFocus.bind(this);
    // this.onPageBlur = this.onPageBlur.bind(this);
    // this.connectToSocket = this.connectToSocket.bind(this)
  }

  componentDidMount(){
    // window.addEventListener('focus', this.onPageFocus);
    // window.addEventListener('blur', this.onPageBlur);
    this.initNotifs()
    //this.connectToSocket()
  }

  initNotifs() {
    if (SocketControl.getInstance().checkNotificationPermission() == "default"){
      AlertMessage.getInstance().show({
        show:true,
        title:'Enable Notifications?',
        message:"We'd like to send you notifications so your tasks can stay current",
        acceptText:'Yes',
        acceptCallBack:SocketControl.getInstance().requestNotificationPermission,
        cancelText:"Not now",
        cancelCallBack: () => {}
      })
    }
  }

  render(){
      return(
        <div className={`${theme[this.props.theme]} wrapper-main-app`} key="MainAppInner">
         <TitleBar theme={this.props.theme} key="TitleBar"/>
         <TasksView theme={this.props.theme} key="TasksView"/>
         <NewTaskInput theme={this.props.theme}/>
         <Subscriptions key="SubScreen"/>
        </div>
      )
  }

}

export default MainApp;