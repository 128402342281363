import Auth from './Auth';
import User from './User';
import Tasks from './Tasks';
import Subscriptions from './Subscriptions';
import Payments from './Payments';
import Misc from './Misc';
import Attachments from './Attachements';

export default class Jude {

    constructor(environment, program, xApiKey) {
        this.initializeClasses(environment, program, xApiKey);
    }

    initializeClasses(environment, program, xApiKey) {
        this.Auth = new Auth(environment, program, xApiKey, this.tokenUpdated);
        this.User = new User(environment, program, xApiKey, this.tokenUpdated);
        this.Tasks = new Tasks(environment, program, xApiKey, this.tokenUpdated);
        this.Subscriptions = new Subscriptions(environment, program, xApiKey, this.tokenUpdated);
        this.Payments = new Payments(environment, program, xApiKey, this.tokenUpdated);
        this.Misc = new Misc(environment, program, xApiKey, this.tokenUpdated);
        this.Attachments = new Attachments(environment, program, xApiKey, this.tokenUpdated);

    }

    // resumeFromToken open connection, return profile
    resumeFromToken(token) {
        this.tokenUpdated(token);
        return new Promise((resolve, reject) => {
            this.User.getProfile()
                .then(user => {
                    this.Auth.user = user;
                    this.Auth.initiateSocket();
                    // this.Auth.bindToChat(messageRecievedCallback);
                    resolve(user);
                })
                .catch(error => reject(error));
        });
    }

    tokenUpdated = (token, blacklisted) => {
        // Makes sure token is consistant accross classes
        if(blacklisted) {
            localStorage.removeItem('hjToken');
        } else {
            localStorage.setItem('hjToken', token);
        }
        
        this.User.setToken(token);
        this.Auth.setToken(token);
        this.Tasks.setToken(token);
        this.Subscriptions.setToken(token);
        this.Payments.setToken(token);
        this.Misc.setToken(token);
        this.Attachments.setToken(token);
    }
}