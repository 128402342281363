import AlertLoader from "../Components/Overlay/AlertLoader"
import PaymentModel from "../Model/PaymentModel"
import HeyJudeUtils from "../Utils/HeyJudeUtils"

export default class PaymentControl {

  static getInstance() {
    if (PaymentControl.instance === undefined) {
      PaymentControl.instance = new PaymentControl()
    }
    return PaymentControl.instance
  }

  async getPaymentMethods() {
    AlertLoader.getInstance().show({show:true, message: "Checking payment methods"})

    let response = await HeyJudeUtils.JudeManager().Payments.methods().then(
      data => {
        return {result:true, data: data.methods || []}
      }
    ).catch( e => {
      return {result:false, data: null}
    })

    AlertLoader.getInstance().show({show:false})

    if (response.result) {
      PaymentModel.getInstance().populateData(response.data)
    } else {
      alert("issue at p methods", response.data, "hi")
    }

  }


}