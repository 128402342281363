import React from 'react';
import './RatingStar.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const iconStarFull = <FontAwesomeIcon icon={["fas", "star"]} />
const iconStarOutline = <FontAwesomeIcon icon={["far", "star"]} />

class RatingStar extends React.PureComponent {

  constructor(props){
    super(props)

    this.renderRating = this.renderRating.bind(this)
    this.onRatingPressed = this.onRatingPressed.bind(this)
  }

  renderRating(){
    if(this.props.value <= this.props.rating) {
      return iconStarFull
    } else {
      return iconStarOutline
    }
  }

  onRatingPressed(){
    if(this.props.onRatingUpdated !== undefined) {
      this.props.onRatingUpdated(this.props.value)
    }
  }

  render(){
    return(
      <div className="RatingStar" onClick={this.onRatingPressed}>
        {this.renderRating()}
      </div>
    )
  }
}

export default RatingStar
