import React from 'react';
import './MessageInput.scss';
import theme from '../../../../Theme/theme.module.scss';

import Button from '../../../Button';
import InputTextArea from '../../../InputTextArea';
import MessageModel from '../../../../Model/MessageModel';
import MediaControl from '../../../../Control/MediaControl';
import ConnectionType from '../../../../Connect/ConnectionType';
import MessageControl from '../../../../Control/MessageControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconImage = require('../../../../Assets/Img/iconUpload.svg')

const iconSend = require('../../../../Assets/Img/iconSend.png')

class MessageInput extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state = {
      currentInput:"",
      currentInputIsFeedback:false,
      show:false,
    }

    this.setMediaSelectorRef = this.setMediaSelectorRef.bind(this);
    this.onMediaAddClick = this.onMediaAddClick.bind(this);
    this.onMediaAddChange = this.onMediaAddChange.bind(this);

    this.taskSelected = this.taskSelected.bind(this);
    this.onTaskData = this.onTaskData.bind(this);

    this.onInputChange = this.onInputChange.bind(this);

    this.setInputReference = this.setInputReference.bind(this);

    this.sendMessage = this.sendMessage.bind(this);
    this.sendMessageByEnter = this.sendMessageByEnter.bind(this);
  }

  componentDidMount(){
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_TASK_SELECTED, this.taskSelected);
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onTaskData);
  }

  componentWillUnmount(){
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_SELECTED, this.taskSelected);
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onTaskData);
  }

  setInputReference(ref){
    this.inputText = ref;
  }

  taskSelected(taskId){
    this.taskId = taskId
  }

  onTaskData(data){
    let showBar = (data.data.status === "Cancelled" || data.data.status === "Closed") ? false : true

    if(this.state.show != showBar) {
      this.setState({show:showBar})
    }
  }

  setMediaSelectorRef(ref){
    this.mediaSelector = ref;
  }

  onMediaAddClick(){
    this.mediaSelector.click();
  }

  onMediaAddChange(e){
    e.persist();
    MediaControl.getInstance().addMediaToUploadSet(e.target.files)
  }

  onInputChange(value){
    this.setState({
      currentInput:value
    });
  }

  onFeedbackMessageCheckPressed(e) {
    this.setState({
      currentInputIsFeedback:e.target.checked
    });
  }

  sendMessageByEnter(e){
    if(e.key === 'Enter'){
      this.sendMessage();
    }
  }
  sendMessage(){
    MessageControl.getInstance().sendAssumedSuccessMessages(this.taskId,this.state.currentInput,this.state.currentInputIsFeedback)
    this.onInputChange("");
    this.inputText.clear();
    document.getElementById("FeedbackCheck").checked = false;
    this.setState({
      currentInputIsFeedback:false
    });
    MediaControl.getInstance().clear()
  }

  render(){
    if(!this.state.show){
      return null;
    }

    return(
      <div className="MessageInputContainer" key="MessageInput">
        <button onClick={this.onMediaAddClick}>
          <input
            onClick={(e)=>{e.target.value = ''}}
            ref={ref => this.setMediaSelectorRef(ref)}
            className="FileSystem"
            onChange={this.onMediaAddChange}
            type="file"
            accept="image/*,audio/*"
            id="file"
            multiple={true}
          /><p className="tiny">Attach File</p>
          <FontAwesomeIcon className="AttachButton" icon="paperclip" />
        </button>
        <InputTextArea
          setReference={this.setInputReference}
          id='message'
          defaultValue={this.state.currentInput}
          placeholder='Message'
          onChange={this.onInputChange}
        />
        <div>
          <input className="FeedbackMessageCheck" id ="FeedbackCheck" type="checkbox" onChange={(e) => this.onFeedbackMessageCheckPressed(e)} />
          <label for="FeedbackMessageCheck">Feedback</label>
        </div>
        <button onClick={this.sendMessage}>
          <FontAwesomeIcon className="SendButton" icon="arrow-circle-right" />
        </button>
      </div>
    )
  }

}

export default MessageInput;
