import React, { Component } from 'react';
import './AppCorrected.css';

import AlertLoader from './Components/Overlay/AlertLoader'
import AlertMessage from './Components/Overlay/AlertMessage'
import PaymentOverlay from './Components/Overlay/PaymentOverlay'
import SignIn from './Screens/SignIn/index';
import TokenSignIn from './Screens/TokenSignIn/index';
import MainApp from './Screens/MainApp/index';
import HeyJudeUtils from './Utils/HeyJudeUtils'
import SignUp from './Screens/SignUp';

import ConnectionType from './Connect/ConnectionType';
import UserModel from './Model/UserModel';
import ForgotPassword from './Screens/ForgotPassword';
import SocketControl from './Control/SocketControl'
import { Socket } from 'net';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far);

class App extends Component {

  constructor(props){
    super(props);
    HeyJudeUtils.JudeManager();

    this.state = {
      checkingSession:true,
      needsSignIn:false,
      needsSignUp:false,
      needsReset:false,
      themeName: 'default',
    };

    this.checkForSession = this.checkForSession.bind(this);
    this.onSignInSuccess = this.onSignInSuccess.bind(this);
    this.onSignOutSuccess = this.onSignOutSuccess.bind(this);
    this.onResetPasswordRequest = this.onResetPasswordRequest.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderSignIn = this.renderSignIn.bind(this);
    this.renderMainApp = this.renderMainApp.bind(this);
    this.renderForgotPassword = this.renderForgotPassword.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.onTokenSignIn = this.onTokenSignIn.bind(this);
  }

  componentWillMount(){
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_SIGN_IN, this.onSignInSuccess)
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_SIGN_OUT, this.onSignOutSuccess)
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_FORGOT_PASSWORD, this.onResetPasswordRequest)
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_SIGN_UP, this.onSignUp)
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_TOKEN_SIGN_IN, this.onTokenSignIn)
  }

  componentDidMount() {
    if(this.state.checkingSession){
      this.checkForSession()
    }
  }

  urlRedirect() {
    if (window.location.pathname.toLowerCase().includes("signup")) {
      return {checkingSession:false, needsSignUp:true, needsSignIn: false}
    } else {
      return {checkingSession:false, needsSignUp:false, needsSignIn: true}
    }
  }

  async checkForSession(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let paymentLinkSuccess = params.get('payment_link_success');

    AlertLoader.getInstance().show({
      show:true,
      message:'loading...'
    });

    let storedToken = localStorage.getItem("hjToken");
    var errorStatusCode = -1;

    if(storedToken === null){
      this.setState(this.urlRedirect())
    }else{
      let response = await HeyJudeUtils.instance.resumeFromToken(storedToken)
      .then( (data) => {
        // SocketControl.connectedFromResume = true;
        // HeyJudeUtils.JudeManager().Auth.bindToChatStatus(SocketControl.getInstance().onSocketStatus)
        return {result: true, data: data}
      })
      .catch( (err) => {
        errorStatusCode = err.status;
        return {result: false, data: null}
      });

      if(response.result){
        UserModel.getInstance().populateData(response.data, true);
        this.setState({
          checkingSession:false,
          needsSignIn:false,
          needsSignUp:false,
        })
      } else {
        this.setState(this.setState(this.urlRedirect()))
      }
    }

    AlertLoader.getInstance().show({show:false});
    let customParams = params.get('custom_param1');
    let paymentLinkSuccessCheck = paymentLinkSuccess ? (paymentLinkSuccess === 'true') : '';
    if(paymentLinkSuccess && paymentLinkSuccessCheck === true){
      AlertLoader.getInstance().show({show:true,message:'Payment method linked successfully!'});
      if(customParams == null){
        window.history.replaceState({}, document.title, "/");
      }
      setTimeout(()=>{
        AlertLoader.getInstance().show({show:false,message:''});
      },3000)
    } else if (paymentLinkSuccess && paymentLinkSuccessCheck === false) {
      AlertLoader.getInstance().show({show:true,message:'Payment link failed!'});
      if(customParams == null){
        window.history.replaceState({}, document.title, "/");
      }
      setTimeout(()=>{
        AlertLoader.getInstance().show({show:false,message:''});
      },3000)
    }
  }

  componentWillUnmount(){
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_SIGN_IN, this.onSignInSuccess);
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_SIGN_OUT, this.onSignOutSuccess);
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_FORGOT_PASSWORD, this.onResetPasswordRequest);
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_SIGN_UP, this.onSignUp);
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_TOKEN_SIGN_IN, this.onTokenSignIn);
  }

  onSignUp(data){
    this.setState({
      needsSignUp:data.signUp,
    })
  }
  onResetPasswordRequest(data){
    this.setState({
      needsReset:data.show,
    })
  }

  onSignInSuccess(){
    this.setState({
      needsSignIn:false,
      needsSignUp:false,
    })
  }

  onSignOutSuccess(){
    this.setState({
      needsSignIn:true,
    })
  }

  onTokenSignIn(data){
    this.setState({
      needsTokenSignIn:true,
    })
  }

  renderSignIn(){
    return (<SignIn theme={this.state.themeName} goToSignUp={this.gotoSignUp}key="SignIn" />)
  }

  gotoSignUp() {
    this.setState({needsSignUp:true, needsSignIn: false})
  }

  renderTokenSignIn(){
    return (<TokenSignIn theme={this.state.themeName} key="TokenSignIn"/>)
  }

  renderSignUp(){
    return(<SignUp theme={this.state.themeName} key="SignUp" backToSignIn={() => this.backToSignIn()}/>)
  }

  backToSignIn() {
    this.setState({needsSignUp: false, needsSignIn: true})
  }

  renderMainApp(){
    return (<MainApp theme={this.state.themeName} key="MainApp"/>)
  }

  renderForgotPassword(){
    if(!this.state.needsReset){
      return null;
    }

    return (<ForgotPassword theme={this.state.themeName} key="ForgotPassword"/>)
  }

  renderView(){
    if (this.state.checkingSession) {
      return null
    }

    if (this.state.needsSignUp) {
      return this.renderSignUp()
    }

    if (this.state.needsSignIn) {
      return this.renderSignIn()
    }

    return this.renderMainApp()
  }

  render() {
    return(
      <div className="wrapper" key="AppInner">
        {this.renderView()}
        {this.renderForgotPassword()}
        <AlertLoader theme={this.state.themeName} key="AlertLoader"/>
        <AlertMessage theme={this.state.themeName} key="AlertMessage"/>
        <PaymentOverlay theme={this.state.themeName} key="PaymentOverlay"/>
      </div>
    )
  }
}

export default App;
