import React from 'react'

import './Subscriptions.scss'
import theme from '../../../Theme/theme.module.scss';
import SubscriptionModel from '../../../Model/SubscriptionModel';
import SubscriptionControl from '../../../Control/SubscriptionControl';
import UserModel from '../../../Model/UserModel';
import ConnectionType from '../../../Connect/ConnectionType';
import SubscriptionItem from './SubscriptionItem';
import Button from '../../Button';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import PaymentModel from '../../../Model/PaymentModel';
import PaymentControl from '../../../Control/PaymentControl';
import AlertMessage from '../../Overlay/AlertMessage';

export default class Subscriptions extends React.PureComponent {

  constructor(props) {
    super(props)
    this.onPayMethods = this.onPayMethods.bind(this)
    this.onSubscribe = this.onSubscribe.bind(this)
    this.onToggleDisplay = this.onToggleDisplay.bind(this)
    this.onSubStatus = this.onSubStatus.bind(this)
    this.onSubOptions = this.onSubOptions.bind(this)

    this.status = SubscriptionModel.getInstance().getStatus()
    this.options = SubscriptionModel.getInstance().getData()
    this.payMethods = PaymentModel.getInstance().getData()

    this.state = {
      show:false
    }
  }

  componentDidMount() {
    PaymentModel.getInstance().addConnection(ConnectionType.TYPE_PAYMENT_DATA_UPDATE, this.onPayMethods)
    SubscriptionModel.getInstance().addConnection(ConnectionType.TYPE_SUBSCRIPTION_TOGGLE_SHOW, this.onToggleDisplay)
    SubscriptionModel.getInstance().addConnection(ConnectionType.TYPE_SUBSCRIPTION_STATUS_UPDATE, this.onSubStatus)
    SubscriptionModel.getInstance().addConnection(ConnectionType.TYPE_SUBSCRIPTION_OPTIONS_UPDATE, this.onSubOptions)

  }

  componentWillUnmount(){
    PaymentModel.getInstance().removeConnection(ConnectionType.TYPE_PAYMENT_DATA_UPDATE, this.onPayMethods)
    SubscriptionModel.getInstance().removeConnection(ConnectionType.TYPE_SUBSCRIPTION_TOGGLE_SHOW, this.onToggleDisplay)
    SubscriptionModel.getInstance().removeConnection(ConnectionType.TYPE_SUBSCRIPTION_STATUS_UPDATE, this.onSubStatus)
    SubscriptionModel.getInstance().removeConnection(ConnectionType.TYPE_SUBSCRIPTION_OPTIONS_UPDATE, this.onSubOptions)
  }

  onToggleDisplay(fromError) {
    this.fromError = fromError
    let isShowing = this.state.show
    this.setState({
      show: !isShowing
    })

    if(!isShowing) {
      this.loadPayMethods()
    }
  }

  loadPayMethods(){
    PaymentControl.getInstance().getPaymentMethods()
  }

  onPayMethods(data) {
    this.payMethods = data
    this.loadSubs()
  }

  loadSubs() {
    SubscriptionControl.getInstance().getStatus()
  }

  onSubStatus(data) {
    this.status = data
    this.loadOptions()
  }

  loadOptions() {
    SubscriptionControl.getInstance().getOptions()
  }

  onSubOptions(data) {
    this.options = data
    this.forceUpdate()
  }

  onSubscribe(id) {
    if (this.payMethods.length === 0) {
      AlertMessage.getInstance().show({
        show:true,
        title:'No Payment Methods',
        message:"You must first add a payment method before you can subscribe. Would you like to do this now?",
        acceptCallBack: () => this.showPayments(),
        cancelText: "Not now"
      })
      return
    }
    SubscriptionControl.getInstance().subscribe(id)
  }

  showPayments() {
    SubscriptionModel.getInstance().dispatch(ConnectionType.TYPE_SUBSCRIPTION_TOGGLE_SHOW)
    UserModel.getInstance().dispatch(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, {force:true})
    PaymentModel.getInstance().dispatch(ConnectionType.TYPE_TOGGLE_PAYMENT_ADD)
  }

  onCloseClick() {
    if(!this.fromError) {
      UserModel.getInstance().dispatch(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW)
    }

    SubscriptionModel.getInstance().dispatch(ConnectionType.TYPE_SUBSCRIPTION_TOGGLE_SHOW)
  }

  renderOptions() {
    let stack = []
    if (this.options) {
      this.options.filter(item => !item.isPro).map(item => {
        stack.push(<SubscriptionItem status={this.status} key={item.id} item={item} onSubscribe={this.onSubscribe}/>)
      })
    }
    return stack
  }

  render() {
    if(!this.state.show) {
      return null
    }

    return (
      <div className='SubscriptionWindow'>
        <Button onClick={() => this.onCloseClick()} text="Back" theme={this.props.theme} className="fz-10"></Button>
        <div className="SubscriptionContainer">
          {this.renderOptions()}
        </div>

      </div>
    )
  }

}
