import BaseModel from "./BaseModel";
import ConnectionType from "../Connect/ConnectionType";

export default class SubscriptionModel extends BaseModel {

  static getInstance() {
    if(SubscriptionModel.instance === undefined) {
      SubscriptionModel.instance = new SubscriptionModel()
    }
    return SubscriptionModel.instance
  }

  constructor(props){
    super(props)
    this.status = undefined
    this.options = undefined
  }

  getStatus() {
    return this.status
  }

  populateStatus(data) {
    this.status = data
    this.dispatch(ConnectionType.TYPE_SUBSCRIPTION_STATUS_UPDATE, this.status)
  }

  getData() {
    return this.options
  }

  populateData(data) {
    this.options = data
    this.dispatch(ConnectionType.TYPE_SUBSCRIPTION_OPTIONS_UPDATE, this.options)
  }

  toggleShow(fromError) {
    this.dispatch(ConnectionType.TYPE_SUBSCRIPTION_TOGGLE_SHOW, fromError)
  }

  clearData() {
    this.status = []
    this.options = []
  }

}