import React from 'react';
import { Jude } from '../HeyJudeSDK'

class HeyJudeUtils{

  static instance;

  static JudeManager(){
    if(HeyJudeUtils.instance === undefined){
        // HeyJudeUtils.instance = new Jude('live', 'heyjude', 'ded96bc73d8754b72a6cc9b0f1d9d310');
        // HeyJudeUtils.instance = new Jude('staging', 'heyjude', 'c5771089f4b1c2d97f2d139553077ec3');
        HeyJudeUtils.instance = new Jude('training', 'heyjude', 'c5771089f4b1c2d97f2d139553077ec3');
    }
    return HeyJudeUtils.instance;
  }
}

export default HeyJudeUtils;
