import AlertLoader from "../Components/Overlay/AlertLoader"
import SubscriptionModel from "../Model/SubscriptionModel"
import HeyJudeUtils from "../Utils/HeyJudeUtils"

export default class SubscriptionControl {

static getInstance() {
  if(SubscriptionControl.instance == undefined) {
    SubscriptionControl.instance = new SubscriptionControl()
  }

  return SubscriptionControl.instance
}

async getStatus() {
  AlertLoader.getInstance().show({show: true, message: 'Getting your subscription status'})
  var response = await HeyJudeUtils.JudeManager().Subscriptions.getStatus().then(
    data => {
      return {result: true, data: data}
    }
  ).catch( e => {
    return {result: false, data: null}
  })
  AlertLoader.getInstance().show({show:false})

  if(response.result) {
    SubscriptionModel.getInstance().populateStatus(response.data)
  } else {
    alert("ISSE AT STATUS")
  }

}

async getOptions() {
  AlertLoader.getInstance().show({show: true, message: 'Getting your subscription options'})
  var response = await HeyJudeUtils.JudeManager().Subscriptions.getOptions().then(
    data => {
      return {result: true, data: data.options}
    }
  ).catch( e => {
    return {result: false, data: null}
  })

  AlertLoader.getInstance().show({show:false})

  if (response.result) {
    SubscriptionModel.getInstance().populateData(response.data) 
  } else {
    alert("issue at options")
  }
}

async subscribe(id) {
  AlertLoader.getInstance().show({show: true, message: 'Subscribing'})
  let response = await HeyJudeUtils.JudeManager().Subscriptions.selectOption(id).then(
    data => {
      return {result:true, data:null}
    }
  ).catch( e => {
    return {result:false, data:null}
  })

  AlertLoader.getInstance().show({show:false})

  if(response.result) {
    this.getStatus()
  } else {
    alert("ISSE AT SUB")
  }
}

async setAutoRenew(value) {
  AlertLoader.getInstance().show({show: true, message:"Updating"})

  let response = await HeyJudeUtils.JudeManager().Subscriptions.setAutoRenew(value).then(
    data => {
      return {result:true, data: data}
    }
  ).catch( e => {
    return {result:true, data: null}
  })

  AlertLoader.getInstance().show({show: false})

  if(response.result) {
    SubscriptionModel.getInstance().populateStatus(response.data)
  } else {
    alert("ISSE AT RENEW")
  }

}


}