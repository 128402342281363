import React from 'react';
import moment from 'moment';
import MessageTypeText from './MessageTypeText';
import MessageTypeImage from './MessageTypeImage';
import MessageTypeAudio from './MessageTypeAudio';
import MessageTypeDocument from './MessageTypeDocument';
import MessageTypePayment from './MessageTypePayment';
import MessageTypeVendor from './MessageTypeVendor';
import Picture from '../../../Picture/index';
import theme from '../../../../Theme/theme.module.scss';
import MessageTypeRating from './MessageTypeRating';
const logo = require('../../../../Assets/Img/operator.png')

class MessageToUser extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    this.renderLayout = this.renderLayout.bind(this);
    this.addTimeStamp = this.addTimeStamp.bind(this);
    this.addSpacer = this.addSpacer.bind(this);
  }

  addTimeStamp(timeStamp,array){
    var finalTimeStamp = moment.unix(timeStamp).format("DD/MM/YYYY - HH:mm");
    array.push(
      <div className="TimeStamp" key={finalTimeStamp}>{finalTimeStamp}</div>
    )
  }

  addSpacer(count,limit,array){
    if(count < limit-1){
      array.push(
        <div className="MessageItemSpacer" key={"to_spacer_"+count}/>
      )
    }
  }

  renderLayout(){
    var displayList = [];

    var limit = this.props.data.length;
    var count = 0;

    for(var dataItem of this.props.data){
      switch(dataItem.type){
        case "text" : displayList.push(<MessageTypeText key={dataItem.id} data={dataItem}/>);
        break;
        case "image" : displayList.push(<MessageTypeImage key={dataItem.id} data={dataItem}/>);
        break;
        case "document" : displayList.push(<MessageTypeDocument key={dataItem.id} data={dataItem}/>);
        break;
        case "audio" : displayList.push(<MessageTypeAudio key={dataItem.id} data={dataItem}/>);
        break;
        case "payment" : displayList.push(<MessageTypePayment key={dataItem.id} data={dataItem}/>)
        break;
        case "vendor" : displayList.push(<MessageTypeVendor key={dataItem.id} data={dataItem}/>);
        break;
        case "rating" : displayList.push(<MessageTypeRating key={dataItem.id} data={dataItem}/>);
        break;
        default : console.log('ignore');
        break
      }

      this.addTimeStamp(dataItem.unixTimeStamp,displayList)
      this.addSpacer(count,limit,displayList)
      count++;
    }

   return displayList;
  }

  render(){


    return(
      <div className="MessageCell">
        <div className="messageIconWrap mr-10">
          <Picture src="https://www.heyjudeapp.com/img/logo.svg" alt="Chat Icon" user></Picture>
        </div>
        <div className={`${theme[this.props.theme]} messageApp messageContent`}>
           {this.renderLayout()}
        </div>
        <div className="MessagePadding"/>
      </div>
    )
  }

}

export default MessageToUser;
