import HeyJudeUtils from "../Utils/HeyJudeUtils";
import Utils from '../Utils/Utils';
import MessageControl from "./MessageControl";
import SocketModel from '../Model/SocketModel';
import TaskModel from '../Model/TaskModel';
import TaskControl from "./TaskControl";
import TaskListContainer from '../Components/Screen/Task/TaskListContainer';

const notifyIcon = require('../Assets/Img/appIcon.png')

class SocketControl {

  static connected = false

  static getInstance(){
    if(SocketControl.instance === undefined){
      SocketControl.instance = new SocketControl();
      SocketControl.instance.setNotify(false);
    }
    
    return SocketControl.instance;
  }

  constructor(){
    this.onSocketMessageReceived = this.onSocketMessageReceived.bind(this);
    this.onSocketStatus = this.onSocketStatus.bind(this);
    this.connect = this.connect.bind(this);
    this.setNotify = this.setNotify.bind(this);
    this.presentNotification = this.presentNotification.bind(this);
    this.requestNotificationPermission = this.requestNotificationPermission.bind(this)
    this.checkNotificationPermission = this.checkNotificationPermission.bind(this)
    
    this.status = "disconnect";
    this.showNotification = false
    this.hasNotificationPermission = false;

    
  }

  connect(){
    HeyJudeUtils.JudeManager().Auth.bindToChat(this.onSocketMessageReceived)
    HeyJudeUtils.JudeManager().Auth.bindToChatStatus(this.onSocketStatus)
  }

  checkNotificationPermission() {
    try {
      let JSNC = Notification
    } catch {
      SocketControl.getInstance().hasNotificationPermission = false
      return "unavailable"
    }

    SocketControl.instance.hasNotificationPermission = Notification.permission
    SocketControl.instance.showNotification = Notification.permission

    return Notification.permission
  }

  requestNotificationPermission(){
    try {
      let JSNC = Notification
    } catch {
      SocketControl.getInstance().hasNotificationPermission = false
      return
    }

    Notification.requestPermission(function(result){
      if (result === "granted") {
            SocketControl.getInstance().setNotify(true);
            SocketControl.getInstance().hasNotificationPermission = true
          }else{
            SocketControl.getInstance().setNotify(false);
            SocketControl.getInstance().hasNotificationPermission = false
          }
    })
  }

  onSocketMessageReceived(data){
    console.log("gotten message")
    if(SocketControl.getInstance().showNotification && SocketControl.getInstance().hasNotificationPermission){
      SocketControl.getInstance().presentNotification(data)
    }

    MessageControl.getInstance().receivedMessages(data)
  }

  onSocketStatus(status){
    this.status = status;
    this.connected = status == SocketModel.SOCKET_CONNECTED
    SocketModel.getInstance().updateSocketStatus(status);
  }

  setNotify(value){
    SocketControl.getInstance().showNotification = value;
  }

  presentNotification(data){
    if(!this.hasNotificationPermission){
      return;
    }
    let taskId = data.taskId;
    var taskData;
    var taskIndex = Utils.getIndexInArrayFromId(taskId,TaskModel.getInstance().getData().openTasks);
    
    let isOpenTask = taskIndex > -1;
    
    if(!isOpenTask){
      taskIndex = Utils.getIndexInArrayFromId(taskId,TaskModel.getInstance().getData().closedTasks);
      taskData = TaskModel.getInstance().getData().closedTasks[taskIndex]
    }else{
      taskData = TaskModel.getInstance().getData().openTasks[taskIndex]
    }

    let title = taskData.title;

    let options = {
      body: 'Task Updated. Click to view',
      icon: notifyIcon,//"icon.jpg",
      dir : "ltr"
    }

    try {
      let JSNC = Notification
    } catch {
      console.log("Notification presenting is prevented")
      return
    }


    if (("Notification" in window)) {
      var notification = new Notification(title, options);
      notification.onclick = function(e) {
              e.preventDefault();
              window.focus();
              (isOpenTask)
                ? TaskControl.getInstance().setViewTaskType(TaskListContainer.TYPE_OPEN)
                : TaskControl.getInstance().setViewTaskType(TaskListContainer.TYPE_CLOSED);
              MessageControl.getInstance().getTask(taskId);
          // };
      };
    }
  }

}

export default SocketControl