import React from 'react';

import TaskListContainer from '../Task/TaskListContainer';
import TaskTypeSwitch from '../Task/TaskTypeSwitch/index';
import TaskCreationOptions from '../Task/TaskCreationOptions/index';
import TaskDetails from '../Task/TaskDetails';
import MessageInput from '../Task/Message/MessageInput';
import MessageMediaUploadList from '../Task/Message/MessageMediaUploadList';
import UserProfile from '../UserProfile'

import style from './tasksView.module.scss'
import theme from '../../../Theme/theme.module.scss';
import UserModel from '../../../Model/UserModel';
import ConnectionType from '../../../Connect/ConnectionType';
import MessageModel from '../../../Model/MessageModel';

class TasksView extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state ={
      showChatArea: false,
    }

    this.onShowChat = this.onShowChat.bind(this);
  }

  componentDidMount(){
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_TASK_TOGGLE_VIEW, this.onShowChat)
  }

  componentWillUnmount() {
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_TOGGLE_VIEW, this.onShowChat)
  }

  onShowChat(data){
    this.setState({
      showChatArea: data.showChat
    })
  }

  render(){
    return(
      <div className={`${style.MyInnerPage}`} key="TasksViewInner">
        <div className={`${style.MyColumnContainer}`} key="MyColumnContainer">
            <UserProfile theme={this.props.theme}/>
            <div className={`${theme[this.props.theme]} ${style.taskListColumn}`} key="TaskListColumn">
              <TaskTypeSwitch theme={this.props.theme} updateTaskType={this.updateTaskType} key="TaskTypeSwitch"/>
              <TaskListContainer theme={this.props.theme} key="TaskListContainer"/>
              <TaskCreationOptions theme={this.props.theme} theme={this.props.theme} key="TaskCreationOptions"/>
            </div>
            <div className={`${theme[this.props.theme]} ${style.chatArea} ${(this.state.showChatArea) ? style.show : style.hide}`}>
              <TaskDetails theme={this.props.theme} key="TaskDetails"/>
              <MessageMediaUploadList theme={this.props.theme} key="MessageMediaUploadList"/>
              <MessageInput theme={this.props.theme} key="MessageInput"/>
            </div>
          </div>
      </div>
    )
  }

}

export default TasksView;