
import HeyJudeUtils from "../Utils/HeyJudeUtils";
import MessageModel from '../Model/MessageModel'
import TaskControl from '../Control/TaskControl'
import AlertMessage from '../Components/Overlay/AlertMessage';
import ConnectionType from "../Connect/ConnectionType";

import MediaControl from "./MediaControl";
import moment from 'moment';
import MediaModel from "../Model/MediaModel";

class MessageControl {

  static getInstance(){
    if(MessageControl.instance === undefined){
      MessageControl.instance = new MessageControl();
    }
    return MessageControl.instance;
  }

  constructor(){
    this.taskId = -100;
    this.pendingIds = {};
  }

  resetOpenTask() {
    this.taskId = -100
  }

  async getTask(taskId,checkForMessages){
    if(this.taskId === taskId){
      return
    }

    this.taskId = taskId;

    if(!checkForMessages){
      MediaControl.getInstance().clear();
    }

    MessageModel.getInstance().dispatch(ConnectionType.TYPE_TASK_SELECTED, taskId);

    let response = await HeyJudeUtils.JudeManager().Tasks.getTask(taskId)
    .then((data) => {
      return {result:true,data:data} })
    .catch((error)=> { return {result:false, data:error} });

    if(response.result){
      TaskControl.getInstance().markTaskAsRead(taskId)
      MessageModel.getInstance().populateData(taskId, response.data);

      if(checkForMessages){
        MessageControl.getInstance().sendAssumedSuccessMessages(taskId,"");
      }else{
        MediaControl.getInstance().clear();
      }
    }else{
      AlertMessage.getInstance().show({
        show:true,
        title:'Oops!',
        message:'We couldn\'t load this task.\n\nWould you like to try again?',
        acceptCallBack:() => MessageControl.getInstance().getTask(taskId),
        cancelText:"No thanks"
      })
    }
  }

  receivedMessages(data){
    MessageModel.getInstance().addReceivedSocketMessages(data.taskId,data)
  }

  async uploadAttachment(file){
    var response = await HeyJudeUtils.JudeManager().Attachments.upload(file)
      .then((data)=> {return {result:true, data:data.attachment.id}})
      .catch((e) => {return {result:false, data:null}})
      
      return response;
  }

  async sendMessage(data){
    var result = await HeyJudeUtils.JudeManager().Tasks.message(data)
      .then((data)=>{return true;})
      .catch((e) => {return false;});

    return result;
  }

  removePendingMessage(id){
    if(this.pendingIds[id]){
      delete this.pendingIds[id]
    }
  }

  addPendingMessage(id){
    this.pendingIds[id] = true
  }

  checkPendingMessage(id){
    return this.pendingIds[id]
  }

  async sendAssumedSuccessMessages(taskId, text, isFeedback){
    let timeStamp = moment().format("X")
    var assumedSuccessMessages = [];
    let tempFiles = MediaModel.getInstance().getData().files;
    let tempKeys = Object.keys(tempFiles);
    var count = 0;
    isFeedback = isFeedback ?? false;

    for(var tempKey of tempKeys){
      let tempFile =  tempFiles[tempKey]
      var mediaMessage;

      if(tempFile.type.includes('image')){
        mediaMessage = {
          pending:true,
          pendingURL:tempFile.url,
          pendingFile:tempFile.file,
          id:timeStamp + "_IMAGE_PENDING_" + count,
          fromUser:true,
          fromUserString:"",
          type:"image",
          unixTimeStamp: timeStamp,
          isFeedback: isFeedback
        }
        assumedSuccessMessages.push(mediaMessage) // refactor to bottom when all types are completed

      }else if(tempFile.type.includes('audio')){
        mediaMessage = {
          pending:true,
          pendingURL:tempFile.url,
          pendingFile:tempFile.file,
          id:timeStamp + "_AUDIO_PENDING_" + count,
          fromUser:true,
          fromUserString:"",
          type:"audio",
          unixTimeStamp: timeStamp,
          isFeedback: isFeedback
        }
        assumedSuccessMessages.push(mediaMessage) // refactor to bottom when all types are completed
      }

      else if (tempFile.type.includes('pdf')){
        mediaMessage = {
          pending:true,
          pendingURL:tempFile.url,
          pendingFile:tempFile.file,
          id:timeStamp + "_DOCUMENT_PENDING_" + count,
          fromUser:true,
          fromUserString:"",
          type:"document",
          unixTimeStamp:timeStamp,
          isFeedback: isFeedback
        }
        assumedSuccessMessages.push(mediaMessage) // refactor to bottom when all types are completed
      }
      // assumedSuccessMessages.push(mediaMessage)
      count++;
    }

    if(text !== ""){
      var textMessage = {
        fromUser: true,
        fromUserString: "",
        pending:true,
        id: timeStamp + "_TEXT_PENDING",
        options: [],
        text: text,
        type: "text",
        unixTimeStamp: timeStamp,
        isFeedback: isFeedback
      }
      assumedSuccessMessages.push(textMessage);
    }
    MessageModel.getInstance().addAssumedSuccessMessages(taskId,assumedSuccessMessages)
  }
}

export default MessageControl;