import React from 'react';
import './UserProfile.scss';
import UserModel from '../../../Model/UserModel';
import UserControl from '../../../Control/UserControl';
import ConnectionType from '../../../Connect/ConnectionType';
import Picture from '../../Picture';
import Button from '../../Button';
import theme from '../../../Theme/theme.module.scss';
import InputText from '../../InputText';
import MMenu from '../../MMenu';
import MModalButton from "../../MModalButton";
import PoweredBy from '../../../Assets/Img/powered-by.png';
import SubscriptionModel from '../../../Model/SubscriptionModel';
import PaymentControl from '../../../Control/PaymentControl';
import PaymentModel from '../../../Model/PaymentModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserProfile extends React.PureComponent {

  static defaultProps = {
    theme: 'default'
  };

    constructor(props) {
        super(props);
        this.userData = UserModel.getInstance().getData();
        this.state = {
            show: false,
            showEdit:false,
            showCardDetails:false,
            transition:'hide',
            noCover: true,
            iframeUrl: '',
            cardDetailsForm: true,
            threeDSecureUrl: "",
            userImage: null,
            userImageType: '',
            userImageValid: true,
            userImageFile: null
        };
        this.onUserData = this.onUserData.bind(this)
        this.onToggleProfile = this.onToggleProfile.bind(this);
        this.onTogglePaymentAdd = this.onTogglePaymentAdd.bind(this);
        this.onSignOutPressed = this.onSignOutPressed.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.renderCardDetails = this.renderCardDetails.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.currentImage = this.currentImage.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
    }

    componentDidMount() {
        UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_DATA, this.onUserData)
        UserModel.getInstance().addConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
        PaymentModel.getInstance().addConnection(ConnectionType.TYPE_TOGGLE_PAYMENT_ADD, this.onTogglePaymentAdd)

    }

    componentWillUnmount() {
        UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_DATA, this.onUserData)
        UserModel.getInstance().removeConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
        PaymentModel.getInstance().removeConnection(ConnectionType.TYPE_TOGGLE_PAYMENT_ADD, this.onTogglePaymentAdd)
    }

    onUserData(data) {
      this.userData = data
      this.forceUpdate()
    }

    onTogglePaymentAdd() {
      let showAddPayment = !this.state.showCardDetails
      this.setState({showCardDetails: showAddPayment})
    }

    onToggleProfile(data) {
      if(data && data.force) {
        this.setState({
          noCover: false,
          show: true//!this.state.show
        });
        setTimeout(()=>{
          this.setState({transition: 'show'});
        },50)
        return
      }


      if(this.state.show){
        this.setState({
          transition: 'hide',
          showEdit: false,
          showCardDetails: false,
          checkoutId:'',
          userImage: null
        });
        setTimeout(()=>{
          this.setState({
            noCover: true,
            show: !this.state.show
        });
        },450)
      }
      else{
        this.setState({
          noCover: false,
          show: !this.state.show
        });
        setTimeout(()=>{
          this.setState({transition: 'show'});
        },50)
      }
    }

    onSignOutPressed() {
        UserControl.getInstance().signOut()
    }

    selectImage(e){
      let file = e.target.files[0];
      if(file){
        let formData = new FormData();
        formData.append('profile_image', file, file.name);

        let url = URL.createObjectURL(file);
        let img = new Image();

        img.src = url;
        img.onload = () =>{
          if(
            img.width < 2000 &&
            img.height < 2000 &&
            (file.type.toLowerCase().includes('jpeg') || file.type.toLowerCase().includes('png'))
          ){
            this.setState({
              userImage: url,
              userImageType: file.type,
              userImageValid: true,
              userImageFile: formData
            });

            UserControl.getInstance().updateProfileImage({profile_image: formData})

          }
          else{
            this.setState({
              userImageValid: false
            })
          }
        }
      }
    }

    settingProfile = () => {
        this.setState({showEdit:!this.state.showEdit})
    };

    settingSubscription = () => {
      UserModel.getInstance().toggleUserProfile()
      SubscriptionModel.getInstance().toggleShow()

    }

    settingCardDetails = async () => {
        let getPaymentLink = UserControl.getInstance().getPaymentLink();
        await getPaymentLink.then(response => {
            this.setState({paymentLink:response.data});
        });
        let response = await UserControl.getInstance().getUserPaymentMethods();
        if(response){
            let paymentMethods = this.createPaymentMethods(response.payment_methods);
            this.setState({paymentMethods:paymentMethods});
            this.setState({showCardDetails:!this.state.showCardDetails});
        }
    };

    saveProfile = async () => {
      if(this.state.userImageValid){
        let response = await UserControl.getInstance().updateProfile({
          lastName: this.fldLastName.getCurrentValue(),
          email: this.fldEmail.getCurrentValue(),
          profileImage: (this.state.userImage) ? this.state.userImageFile : this.userData.profileImage,
          greenerChoices: this.userData.greenerChoices,
          pushNotifications: this.userData.pushNotifications,
          firstName: this.fldFirstName.getCurrentValue(),
        });

        if(response){
          this.setState({showEdit:!this.state.showEdit});
        }
      }
    };

    currentImage(){
      if(this.state.userImage){
        return this.state.userImage;
      }
      else{
        return this.userData.profileImage;
      }
    }

    createPaymentMethods(methods) {
        let paymentMethods = "";
        for (let j = 0; j < methods.length; j++) {
            paymentMethods += "<p class='username'>" + methods[j].last_four_digits + "</p>"
        }
        return paymentMethods
    };

    renderCardForm(){
      //window.innerWidth, height: window.innerHeight

        return <div>
            <h1 className="Hey_Title">Link a card below.</h1>
            <iframe id="payment_link" align={'center'} scrolling={'yes'} width={window.innerWidth * 0.75} height={window.innerHeight * 0.75}  src={this.state.paymentLink}></iframe>
        </div>
    };

    renderCardDetails(){
        if(this.state.showCardDetails){
            return(
                <React.Fragment>
                    <div className="text-center">
                        <p className="username">Linked Cards</p>
												<p className="userphone">You can manage your subscription, as well as make secure payments directly through the Hey Jude app by linking your credit card below. You currently have a stored card with a number ending in: </p>
                        <br/>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.paymentMethods
                            }}>
                        </div>
                        <br/><br/>
                        <MModalButton title="Link a payment method">
                            {this.renderCardForm()}
                        </MModalButton>
                    </div>
                </React.Fragment>
            )
        }
    }

    renderEdit(){
      if(this.state.showEdit){
        return(
          <React.Fragment>
            <p className="fz-13 bold mt-10 light">Email</p>
            <InputText
                theme={this.props.theme}
                className="mt-10 h-40"
                setReference={(ref) => this.fldEmail = ref}
                defaultValue={this.userData.email}
                id="userEmail"
                placeholder="Email"
                type="text"
            />
            <div className="wrapper wrapper_flex-center mt-20 mb-30">
              <Button
                theme={this.props.theme}
                text="Save"
                className="h-40 panelButtonSmall"
                onClick={this.saveProfile}
              ></Button>&nbsp;
              <Button
                theme={this.props.theme}
                text="Cancel"
                className="h-40 panelButtonSmall mb-30"
                secondary
                onClick={this.settingProfile}
              ></Button>
							<br/><br/>
            </div>
          </React.Fragment>
        )
      }
    }

    hideContainer(){
      if (this.state.transition === 'hide') {
          return 'userProfileHide';
      }
      else{
        return '';
      }
    }
    hideCover(){
      if (this.state.transition === 'hide') {
          return 'wrapper_cover-hide';
      }
      else{
        return '';
      }
    }
    noCover(){
      if(this.state.noCover){
        return {display: 'none'};
      }
      else{
        return {};
      }
    }

    render() {

        return (
          <React.Fragment>
            <div className={`${theme[this.props.theme]} ${this.hideContainer()} userProfileContainer`}>

							<div class="container-fluid header">
								<div class="row">
									<div class="col-8">
										<p className="username">{this.userData.firstName} {this.userData.lastName}</p>
										<p className="userphone">{this.userData.mobile}</p>
									</div>
									<div class="col-4 text-end">
										<a href="#" className="iconUser" onClick={this.settingProfile}><FontAwesomeIcon icon="user" /></a>
									</div>
								</div>
								<div class="row actions">
									<div class="col-6">
									<a href="#" className="panelButtonSmall noBorder" onClick={this.settingCardDetails}><FontAwesomeIcon icon="credit-card" />  &nbsp; Payments </a>
									</div>
									<div class="col-6 text-end">
										<a href="#" className="panelButtonSmall noBorder" onClick={this.settingSubscription}>Subscription &nbsp; <FontAwesomeIcon icon="check-square" /> </a>
									</div>
								</div>
							</div>

              <div className='wrapper wrapper_flex-column-center'>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
                {this.renderEdit()}
              </div>

              <div className='wrapper wrapper_flex-column-center mt-100'>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
                  {this.renderCardDetails()}
              </div>

							<div class="container-fluid footer">
								<div class="row">
									<div class="col-6 text-start hide-xl">
              			<a className="panelButtonSmall" onClick={this.onSignOutPressed}><FontAwesomeIcon icon="sign-out-alt" />  &nbsp; Sign Out</a>
									</div>
									<div class="col-6 text-end hide-xl">
              			<a className="panelButtonSmall" onClick={this.onToggleProfile}>Close &nbsp; <FontAwesomeIcon icon="times" /></a>
									</div>
								</div>
								<div class="row">
									<div class="col-12 text-center mt-30 mb-20">
              			<a className="terms" onClick={this.onSignOutPressed}>Terms and conditions</a> &nbsp;
              			<a className="terms" onClick={this.onToggleProfile}>Privacy policy and disclaimer</a>
										<br/><br/>
									</div>
								</div>
							</div>

            </div>

						<div onClick={this.onToggleProfile} style={this.noCover()} className={`${this.hideCover()} wrapper wrapper_cover wrapper_cover-fixed hide-xl`}></div>

          </React.Fragment>
        )
    }
}

export default UserProfile;
