import BaseModel from "./BaseModel";
import TaskModel from "./TaskModel";
import ConnectionType from "../Connect/ConnectionType";
import MessageControl from "../Control/MessageControl";

class MessageModel extends BaseModel{

  static getInstance(){
    if(MessageModel.instance === undefined){
      MessageModel.instance = new MessageModel();
    }
    return MessageModel.instance;
  }

  constructor(){
    super();
    this.data={};
  }

  populateData(taskId,data){
    this.data[taskId] = data;
    this.dispatch(ConnectionType.TYPE_MESSAGE_DATA,{id:taskId, data:data})
  }

  clearMessages(){
    this.dispatch(ConnectionType.TYPE_MESSAGE_CLEAR)
  }

  addAssumedSuccessMessages(taskId,data){
    var currentTaskMessages = this.data[taskId].messages || []
    
    for(var dataItem of data){
      currentTaskMessages.push(dataItem)
    }

    this.dispatch(ConnectionType.TYPE_MESSAGE_DATA,{id:taskId, data:this.data[taskId]})
  }

  addReceivedSocketMessages(taskId,data){
    var taskData = this.data[taskId];
    if(taskData === undefined){
      taskData = {messages:[data]}
      this.data[taskId] = taskData;
    } else {
      let found = taskData.messages.some(item => item.id === data.id);
      if (!found) {
        taskData.messages.push(data);
      }
    }
    
    let isCurrentTask = taskId == MessageControl.getInstance().taskId
    console.log(MessageControl.getInstance().taskId,":: message for task ::" ,taskId)
    this.updateUnreadState(taskId, !isCurrentTask)
    this.dispatch(ConnectionType.TYPE_SOCKET_MESSAGE_DATA,{id:taskId, data:this.data[taskId]})

  }

  updateUnreadState(taskId, value = true) {
      let taskIndex = TaskModel.getInstance().getTaskFromOpenList(taskId)
      if (taskIndex > -1) {
        console.log(taskId, value)
        TaskModel.getInstance().data.openTasks[taskIndex].unread = value
        TaskModel.getInstance().dispatch(ConnectionType.TYPE_TASK_OPEN_DATA, TaskModel.getInstance().data.openTasks)

      }
  }

  clearData() {
    this.data = {}
  }

}

export default MessageModel;