import React from 'react';
import MessageControl from '../../../../Control/MessageControl';
class MessageTypeText extends React.PureComponent {

  

  constructor(props){
    super(props);

    this.mailRegex = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)


    //DO NOT DELETE
    //this.urlRegex = new RegExp(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi)
    this.urlRegex = new RegExp(/\b(https?:\/\/\S+)|\b(www\S+)/g)

    this.sendMessage = this.sendMessage.bind(this);
    this.attempts = 0;

    this.state = {
      resend : false
    }

    this.renderResend = this.renderResend.bind(this);
    this.resendMessage = this.resendMessage.bind(this);

    this.createMailLink = this.createMailLink.bind(this)
    this.createMarkup = this.createMarkup.bind(this)
    this.getClickableText = this.getClickableText.bind(this)
  }

  componentDidMount(){
    if(this.props.data.pending){
      this.sendMessage();
    }
  }

  resendMessage(){
    this.setState({resend:false});
    this.sendMessage();
  }

  async sendMessage(){
    var result = await MessageControl.getInstance().sendMessage({
      type:'text',
      text:this.props.data.text,
      isFeedback:this.props.data.isFeedback,
      taskId:this.props.data.taskId,
    });

    if(!result){
      this.setState({
        resend:true,
      })
    }
  }

  renderResend(){
    if(!this.state.resend){
      return null;
    }
    
    return(
      <div>
        <button onClick={this.resendMessage}>
          Error, press to resend
        </button>
      </div>
    )
  }

  createUrlLink(value) {
    return value.replace(this.urlRegex, "<a href='$1$2' target='_blank'>$1$2</a>")
  }

  createMailLink(value) {
    return this.createUrlLink(value.replace(this.mailRegex, "<a href='mailto:$1' target='_blank'>$1</a>"))
  }

  createMarkup(value) {
    return {__html: this.createMailLink(value)};
  }
  
  getClickableText(value) {
    return <div className="fz-16" dangerouslySetInnerHTML={this.createMarkup(value)}/>;
  }

  render(){
    return this.getClickableText(this.props.data.text)
  }

}

export default MessageTypeText;