import BaseModel from './BaseModel';
import ConnectionType from '../Connect/ConnectionType'

class UserModel extends BaseModel{

  static getInstance(){
    if(UserModel.instance === undefined){
      UserModel.instance = new UserModel();
    }
    return UserModel.instance;
  }

  populateData(data, fromSignIn){
    this.data = {
      ...data
    };
    this.dispatch(ConnectionType.TYPE_USER_DATA, data);
    
    if(fromSignIn){
      this.dispatch(ConnectionType.TYPE_USER_SIGN_IN)
    }
  }

  updateData(data) {
    this.data = {
      ...this.data,
      ...data,
    }

    this.dispatch(ConnectionType.TYPE_USER_DATA, this.data);
  }

  toggleUserProfile(){
    this.dispatch(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW)
  }

  clearData(){
    this.data = {}
    this.dispatch(ConnectionType.TYPE_USER_SIGN_OUT)
  }

}

export default UserModel