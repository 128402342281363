import React from 'react';
import moment from 'moment';
import MessageTypeText from './MessageTypeText';
import MessageTypeImage from './MessageTypeImage';
import MessageTypeDocument from './MessageTypeDocument';
import MessageTypePayment from './MessageTypePayment';
import MessageTypeAudio from './MessageTypeAudio';
import MessageTypeVendor from './MessageTypeVendor';
import Picture from '../../../Picture/index';

import theme from '../../../../Theme/theme.module.scss';


class MessageFromUser extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.renderLayout = this.renderLayout.bind(this);
    this.addTimeStamp = this.addTimeStamp.bind(this);
    this.addSpacer = this.addSpacer.bind(this);
  }

  addTimeStamp(timeStamp,array){
    var finalTimeStamp = moment.unix(timeStamp).format("DD/MM/YYYY - HH:mm");
    array.push(
      <div className="TimeStamp" key={finalTimeStamp}>{finalTimeStamp}</div>
    )
  }

  addSpacer(count,limit,array){
    if(count < limit-1){
      array.push(
        <div className="MessageItemSpacer" key={"from_spacer_"+count}/>
      )
    }
  }

  renderLayout(){
    var displayList = [];

    var limit = this.props.data.length;
    var count = 0;

    for(var dataItem of this.props.data){
      switch(dataItem.type){
        case "text" : displayList.push(<MessageTypeText key={dataItem.id} data={dataItem}/>);
        break;
        case "image" : displayList.push(<MessageTypeImage key={dataItem.id} data={dataItem}/>);
        break;
        case "document" : displayList.push(<MessageTypeDocument key={dataItem.id} data={dataItem}/>);
        break;
        case "audio" : displayList.push(<MessageTypeAudio key={dataItem.id} data={dataItem}/>);
        break;
        case "payment" : displayList.push(<MessageTypePayment key={dataItem.id} data={dataItem}/>);
        break;
        case "vendor" : displayList.push(<MessageTypeVendor key={dataItem.id} data={dataItem}/>);
        break;
        default : console.log('ignore');
        break
      }

      this.addTimeStamp(dataItem.unixTimeStamp,displayList)
      this.addSpacer(count,limit,displayList)
      count++;
    }

   return displayList;
  }

  render(){
    return(
      <div className="MessageCell">
        <div className="MessagePadding"/>
        <div className={`${theme[this.props.theme]} messageUser messageContent`}>
          {this.renderLayout()}
        </div>
        <div className="messageIconWrap ml-10">
          <div className="ChatIconSpacer"/>
          <Picture src="https://heyjudeapp.com/img/user-hj.png" user alt="Chat Icon"></Picture>
        </div>
      </div>
    )
  }

}

export default MessageFromUser;
