import React from 'react';
import style from './inputTextArea.module.scss';
import theme from '../../Theme/theme.module.scss';
import { ifError } from 'assert';

class InputText extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    // this.currentValue = this.props.defaultValue;
    this.state = {
      currentValue : this.props.defaultValue,
    }
    this.nativeRef = React.createRef();
    this.focusInput = this.focusInput.bind(this);

    this.onInputChange = this.onInputChange.bind(this);
    this.getCurrentValue = this.getCurrentValue.bind(this);
  }

  componentDidMount(){
    if(!this.props.hasOwnProperty('setReference')){
      return;
    }

    this.props.setReference(this)
  }

  focusInput(){
    if(this.nativeRef !== undefined && this.nativeRef !== null){
      this.nativeRef.focus();
    }
  }

  onInputChange(e){
    let value = e.target.value;
    this.setState({currentValue:value})

    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(value);
    }
  }

  getCurrentValue(){
    return this.state.currentValue;
  }

  clear(){
    this.setState({currentValue:""});
  }

  render(){
    return(
      <textarea
        className={`
          ${style.inputText}
          ${theme[this.props.theme]}
          ${(this.props.message) ? style.inputMessage : ''}
          ${(this.props.className) ? this.props.className : ''}
        `}
        ref={(ref) =>{this.nativeRef = ref; this.focusInput()}}
        value={this.state.currentValue}
        id={this.props.id}
        placeholder={this.props.placeholder}
        type={this.props.type}
        onChange={this.onInputChange}
        onKeyDown={this.props.onKeyDown}
        rows="3"
      />
    )
  }

}

export default InputText;
