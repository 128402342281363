import React from 'react';

import './MessageItemContainer.scss'
import UserModel from '../../../../Model/UserModel';

import MessageFromUser from './MessageFromUser';
import MessageToUser from './MessageToUser';
import ConnectionType from '../../../../Connect/ConnectionType';


class MessageItemContainer extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    this.userIcon = UserModel.getInstance().getData().profileImage;
    this.createContentKey = this.createContentKey.bind(this);
    this.renderLayout = this.renderLayout.bind(this);
    this.onUserData = this.onUserData.bind(this)
  }

  componentDidMount() {
    UserModel.getInstance().addConnection(ConnectionType.TYPE_USER_DATA, this.onUserData)
  }

  componentWillUnmount(){
    UserModel.getInstance().removeConnection(ConnectionType.TYPE_USER_DATA, this.onUserData)
  }

  onUserData() {
    this.userIcon = UserModel.getInstance().getData().profileImage;
    this.forceUpdate()
  }

  createContentKey(id,type){
    let key = "content_" + id + "_" + type;
    return key;
  }

  renderLayout(){
    if(this.props.data === undefined || this.props.data.length === 0){
      return [];
    }

    let fromUser = this.props.data[0].fromUser;

    if(fromUser){
      return <MessageFromUser key={this.props.data.id} theme={this.props.theme} userIcon={this.userIcon} data={this.props.data}/>
    }else{
      return <MessageToUser key={this.props.data.id} theme={this.props.theme} userIcon={this.userIcon} data={this.props.data}/>
    }
    
  }

  render(){
    return(
      <div className="MessageItemContainer">
        {this.renderLayout()}
      </div>
    )
  }

}

export default MessageItemContainer;