class ConnectionType{

  static TYPE_USER_DATA             = "TYPE_USER_DATA";
  static TYPE_USER_SIGN_IN          = "TYPE_USER_SIGN_IN";
  static TYPE_USER_SIGN_UP          = "TYPE_USER_SIGN_UP";
  static TYPE_USER_SIGN_OUT         = "TYPE_USER_SIGN_OUT";
  static TYPE_USER_FORGOT_PASSWORD  = "TYPE_USER_FORGOT_PASSWORD";
  static TYPE_USER_TOKEN_SIGN_IN  = "TYPE_USER_TOKEN_SIGN_IN";

  static TYPE_TOGGLE_PROFILE_VIEW = "TYPE_TOGGLE_PROFILE_VIEW";

  static TYPE_TOGGLE_PAYMENT_ADD = "TYPE_TOGGLE_PAYMENT_ADD";

  static TYPE_PAYMENT_DATA_UPDATE = "TYPE_PAYMENT_DATA_UPDATE";

  static TYPE_SUBSCRIPTION_TOGGLE_SHOW = "TYPE_SUBSCRIPTION_TOGGLE_SHOW"
  static TYPE_SUBSCRIPTION_STATUS_UPDATE = "TYPE_SUBSCRIPTION_STATUS_UPDATE"
  static TYPE_SUBSCRIPTION_OPTIONS_UPDATE = "TYPE_SUBSCRIPTION_OPTIONS_UPDATE"

  static TYPE_SHOW_NEW_TASK_CREATE = "TYPE_SHOW_NEW_TASK_CREATE";
  static TYPE_HIDE_NEW_TASK_CREATE = "TYPE_HIDE_NEW_TASK_CREATE";
  static TYPE_TASK_DATA            = "TYPE_TASK_DATA";

  static TYPE_TASK_TYPE_SELECTED  = "TYPE_TASK_TYPE_SELECTED";
  static TYPE_TASK_OPEN_DATA      = "TYPE_TASK_OPEN_DATA";
  static TYPE_TASK_CLOSED_DATA    = "TYPE_TASK_CLOSED_DATA";
  static TYPE_MESSAGE_CLEAR       = "TYPE_MESSAGE_CLEAR";

  static TYPE_TASK_SELECTED       = "TYPE_TASK_SELECTED";
  static TYPE_MESSAGE_DATA        = "TYPE_MESSAGE_DATA";
  static TYPE_TASK_TOGGLE_VIEW    = "TYPE_TASK_TOGGLE_VIEW"
  static TYPE_SOCKET_MESSAGE_DATA = "TYPE_SOCKET_MESSAGE_DATA";
  static TYPE_SOCKET_STATUS_UPDATE = "TYPE_SOCKET_STATUS_UPDATE";

  static TYPE_MEDIA_FOR_UPLOAD_UPDATED = "TYPE_MEDIA_FOR_UPLOAD_UPDATED";
  static TYPE_MEDIA_FOR_TASK_UPDATED  = "TYPE_MEDIA_FOR_TASK_UPDATED";
}

export default ConnectionType;