
class Utils{


  static convertCurrency(currency) {
    switch (currency) {
      case "GBP": return "£"
      default : return "R" 
    }
  }

  static getIndexInArrayFromId(id, array){
    for(var i = 0 ; i < array.length ; i++){
      let item = array[i]
      if(item.id === id){
        return i;
      }
    }
    return -1;
  }

  static extractExtensionFromMimeType(type){
    var arr = type.split('/');
    return arr[arr.length-1];
  }

  static async prepareImageForUpload(file,onComplete){
    var fileData = {
      file:undefined,
      url:undefined,
      id:file.lastModified + "_"+ file.size,
      type:file.type,
    }

    var reader = new FileReader();

    reader.onload = function(e) {
      let img = document.createElement("img");
      img.src = e.target.result
      img.onload = () => {

        let canvas = document.createElement('canvas')
        let baseCtx = canvas.getContext("2d");
        baseCtx.drawImage(img, 0, 0);

        var MAX_WIDTH = 1024;
        var MAX_HEIGHT = 1024;
        var width = img.width;
        var height = img.height;
        
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height)

        canvas.toBlob((blob)=>{
            var fType = Utils.extractExtensionFromMimeType(fileData.type)
            var newFile = new File(
              [blob],
              fileData.id + '.' + fType,
              {type:fileData.type}
            )
            var newUrl = URL.createObjectURL(newFile)

            fileData.file = newFile;
            fileData.url = newUrl;

            onComplete(fileData)
          },
          file.type,0.8
        )
      }
    }
    reader.readAsDataURL(file);
  }

}

export default Utils;