import React from 'react';
import ConnectionType from '../../../Connect/ConnectionType'
import TaskModel from '../../../Model/TaskModel'
import TaskControl from '../../../Control/TaskControl'
import TaskItem from './TaskItem/index';
// import './TaskListContainer.css'
import MessageControl from '../../../Control/MessageControl';

class TasksOpen extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props)

    this.onNewData = this.onNewData.bind(this)
    this.renderList = this.renderList.bind(this);
    this.onCreateNewTask = this.onCreateNewTask.bind(this);

    this.state = {
      data:TaskModel.getInstance().getData().openTasks 
    } 
  }

  componentDidMount(){
    TaskModel.getInstance().addConnection(ConnectionType.TYPE_TASK_OPEN_DATA, this.onNewData)
    TaskControl.getInstance().getOpenTasks();
  }

  componentWillUnmount(){
    TaskModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_OPEN_DATA, this.onNewData)
  }

  onCreateNewTask(){
    TaskModel.getInstance().dispatch(ConnectionType.TYPE_SHOW_NEW_TASK_CREATE)
  }

  onNewData(data){
    this.setState({
      data:[]
    });
    this.setState({
      data:data
    });
    this.forceUpdate();
  }

  renderList(dataSet){
    let displayList = [];
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let selectedTaskId = params.get('custom_param1');
    let selectedTask = false;
     for(var dataItem of dataSet){
       if(selectedTaskId !== null && dataItem.id == selectedTaskId){
         selectedTask = true;
       }
       displayList.push(
         <TaskItem selectedTask={selectedTask} theme={this.props.theme} key={dataItem.id} data={dataItem}/>
       )
    }

    if(displayList.length === 0){
      displayList.push(
        <div className="NoTaskContainer" key="NoTaskContainer">
          <div className="NoTaskContainerText" key="NoTaskContainerText">No Open Tasks</div>
        </div>
      )
    }

    return displayList
  }

  render(){   
    return (
      <div className="wrapper wrapper_tasks-list" key="TasksOpenInner">
        {this.renderList(this.state.data)}
      </div>
    )
  }
}

export default TasksOpen;