import HeyJudeUtils from "../Utils/HeyJudeUtils";
import AlertLoader from '../Components/Overlay/AlertLoader';
import AlertMessage from '../Components/Overlay/AlertMessage';

import UserModel from '../Model/UserModel';
import ConnectionType from "../Connect/ConnectionType";
import { ApiHelper } from '../HeyJudeSDK/Helpers/ApiHelper';
import TaskModel from "../Model/TaskModel";
import SubscriptionModel from "../Model/SubscriptionModel";
import PaymentModel from "../Model/PaymentModel";
import MessageModel from "../Model/MessageModel";
import MediaModel from "../Model/MediaModel";

class UserControl {

  static getInstance(){
    if(UserControl.instance === undefined){
      UserControl.instance = new UserControl();
    }
    return UserControl.instance
  }

  async signIn(details){
    AlertLoader.getInstance().show({show:true,message:'Signing in'})
    var response = await HeyJudeUtils.JudeManager().Auth.signIn(details)
                        .then(data => {return {result:true,data:data}})
                        .catch(error => {
                            var errString = "";
                            for (var err of error.errors){
                              errString += err + '\n';
                            }
                            return {result:false,data:errString}
                          });

    AlertLoader.getInstance().show({show:false,message:''})
    if(response.result){
      UserModel.getInstance().populateData(response.data, true);
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Error Signing In.',
        message:response.data,
      })
    }
  }

    async tokenSignIn(details){
        AlertLoader.getInstance().show({show:true,message:'Signing in'});
        var response = await HeyJudeUtils.JudeManager().Auth.tokenSignIn(details)
            .then(data => {
                return {result:true,data:data}
            })
            .catch(error => {
                var errString = "";
                for (var err of error.errors){
                    errString += err + '\n';
                }
                return {result:false,data:errString}
            });
        AlertLoader.getInstance().show({show:false,message:''})
        if(response.result){
            UserModel.getInstance().populateData(response.data, true);
        } else {
          if(details.token !== null){
              // window.location.href = "https://pwa.staging.tctdigital.xyz/"
          }
          AlertMessage.getInstance().show({
              show:true,
              title:'Error Signing In.',
              message:response.data,
          })
        }
    }

    async signUp(details){
        AlertLoader.getInstance().show({show:true,message:'Signing up'})
        var response = await HeyJudeUtils.JudeManager().Auth.signUp(details, true)
            .then(data => {return {result:true,data:data}})
            .catch(error => {
                var errString = "";
                for (var err of error.errors){
                    errString += err + '\n';
                }
                return {result:false,data:errString}
            });

        AlertLoader.getInstance().show({show:false,message:''})
        if(response.result){
          UserModel.getInstance().populateData(response.data, true);
          // this.signIn({
          //   password: details.password,
          //   username: details.email
          // })
        } else {
            AlertMessage.getInstance().show({
                show:true,
                title:'Error Signing Up.',
                message:response.data,
            })
        }
    }

    async updateProfileImage(imageData) {
      AlertLoader.getInstance().show({show:true,message:'Updating profile'})

      var response = await HeyJudeUtils.JudeManager().User.updateProfile({imageData}).then(
        data => {
          return {result:true, data: data}
        }
      ).catch(error => {
          var errString = "";
          for (var err of error.errors){
              errString += err + '\n';
          }
          return {result:false,data:errString}
      });

      AlertLoader.getInstance().show({show:false,message:''})

      if(response.result){
        UserModel.getInstance().updateData(response.data)

        AlertMessage.getInstance().show({
          show:true,
          title:'Success',
          message: 'Profile was updated.',
        });

        return true;

      } else {
        AlertMessage.getInstance().show({
            show:true,
            title:'Error with updating.',
            message:response.data,
        });
        return false;
      }
    }


    async updateProfile(details){

      AlertLoader.getInstance().show({show:true,message:'Updating profile'})
      var response = await HeyJudeUtils.JudeManager().User.updateProfile(details)
          .then(data => {return {result:true,data:data}})
          .catch(error => {
              var errString = "";
              for (var err of error.errors){
                  errString += err + '\n';
              }
              return {result:false,data:errString}
          });

      AlertLoader.getInstance().show({show:false,message:''})

      if(response.result){
        UserModel.getInstance().updateData(details)

        AlertMessage.getInstance().show({
          show:true,
          title:'Success',
          message: 'Profile was updated.',
        });

        return true;

      } else {
          AlertMessage.getInstance().show({
              show:true,
              title:'Error with updating.',
              message:response.data,
          });
          return false;
      }
    }

    async getUserPaymentMethods() {
        let response = await HeyJudeUtils.JudeManager().User.getPaymentMethods()
            .then(data => {
                return data
            })
            .catch(error => {
                let errString = "";
                for (var err of error.errors) {
                    errString += err + '\n';
                }
                return {result: false, data: errString}
            });

        AlertLoader.getInstance().show({show: false, message: ''});
        return response
    }

  async getPaymentLink(customParams = null){
      let response = await HeyJudeUtils.JudeManager().User.createPaymentLinks(customParams)
          .then((data)=>{return {result:true,data:data.url}})
          .catch((e) => {return {result:false,data:e}});
      return response
  }

    async makePayment(paymentRequestId, paymentMethodId){

        return await HeyJudeUtils.JudeManager().User.pay(paymentRequestId, paymentMethodId)
            .then((data)=>{return {result:true,data:data.url}})
            .catch((e) => {return {result:false,data:e}});
    }

  async getOTP(mobileNumber){
    AlertLoader.getInstance().show({show:true,message:'Sending OTP'})
    var response = await HeyJudeUtils.JudeManager().Auth.forgotPassword(mobileNumber)
                    .then((data)=>{return {result:true,data:null}})
                    .catch((e) => {return {result:false,data:e}})

    AlertLoader.getInstance().show({show:false,message:''})

    if(response.result){
      AlertMessage.getInstance().show({
        show:true,
        title:'Success',
        message:'An OTP has been sent to your mobile device.'
      })
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Error sending OTP',
        message:response.data.errors
                  ? response.data.errors.join("\r\n")
                  : "Something went wrong",
      })
    }

    return response;
  }

  async verifyOTPWithCall(mobileNumber) {
    AlertLoader.getInstance().show({show:true,message:'Sending OTP'})
    var response = await HeyJudeUtils.JudeManager().Auth.verifyPhoneNumberWithCall(mobileNumber)
                    .then((data)=>{return {result:true,data:null}})
                    .catch((e) => {return {result:false,data:e}})

    AlertLoader.getInstance().show({show:false,message:''})

    if(response.result){
      AlertMessage.getInstance().show({
        show:true,
        title:'Success',
        message:'An OTP has been sent to your mobile device.'
      })
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Error sending OTP',
        message:response.data.errors
                  ? response.data.errors.join("\r\n")
                  : "Something went wrong",
      })
    }

    return response;
  }

  async verifyOTP(mobileNumber){
    AlertLoader.getInstance().show({show:true,message:'Sending OTP'})
    var response = await HeyJudeUtils.JudeManager().Auth.verifyPhoneNumber(mobileNumber)
                    .then((data)=>{return {result:true,data:null}})
                    .catch((e) => {return {result:false,data:e}})

    AlertLoader.getInstance().show({show:false,message:''})

    if(response.result){
      AlertMessage.getInstance().show({
        show:true,
        title:'Success',
        message:'An OTP has been sent to your mobile device.'
      })
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Error sending OTP',
        message:response.data.errors
                  ? response.data.errors.join("\r\n")
                  : "Something went wrong",
      })
    }

    return response;
  }

  async resetPassword(otp,password, mobileNumber){
    AlertLoader.getInstance().show({show:true,message:'Resetting your password.'})

    let response = await HeyJudeUtils.JudeManager().Auth.reset(otp, password, mobileNumber)
                    .then((data) => {return {result:true,data:data}})
                    .catch((e) =>{
                      var errString = "";
                      for (var err of e.errors){
                        if(errString !== ""){
                          errString +='\n'
                        }
                        errString += err
                      }
                      return {result:false,data:errString}
                    });

    AlertLoader.getInstance().show({show:false,message:''});

    if(response.result){
      UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_FORGOT_PASSWORD,{show:false})
      UserModel.getInstance().populateData(response.data, true);
    }else{
      AlertMessage.getInstance().show({
        show:true,
        title:'Error',
        message:response.data,
        acceptText:'Ok',
        cancelText:"No",
        acceptCallBack:()=>UserControl.getInstance().resetPassword(otp,password,mobileNumber)
      })
    }
  }

  toggleUserProfile(){
    UserModel.getInstance().toggleUserProfile()
  }

  async getDialingCodes(){
    var response = await HeyJudeUtils.JudeManager().Misc.getCountries()
                    .then((data) => {return {result:true, data:data.countries}})
                    .catch((e) => {return {result:false,data:null}});

    return response;
  }

  async signOut(){
    localStorage.removeItem("hjToken")

    UserModel.getInstance().clearData();
    TaskModel.getInstance().clearData();
    SubscriptionModel.getInstance().clearData();
    PaymentModel.getInstance().clearData()
    MessageModel.getInstance().clearData()
    MediaModel.getInstance().clearData()

    HeyJudeUtils.JudeManager().Auth.signOut()
      .then((data) =>{return data.success})
      .catch((e)=>{return true;})
  }

}

export default UserControl;
