

export default class SubscriptionStatus {

    constructor(json) {
        this.autoRenew = json.auto_renew ? json.auto_renew : false;
        this.currentSubscriptionOption = json.current_subscription_option || null;
        this.chosenSubscriptionOption = json.chosen_subscription_option || null;
        this.expiryText = json.expiry_text || null;
        this.renewalDate = json.renewal_date ? json.renewal_date : null;
        this.valid = json.valid || null;
    }

    toString() {
        return "SubscriptionStatus Object: "
            .concat(' autoRenew: ').concat(this.autoRenew)
            .concat(' currentSubscriptionOption: ').concat(this.currentSubscriptionOption)
            .concat(' chosenSubscriotionOption: ').concat(this.chosenSubscriptionOption)
            .concat(' expiryText: ').concat(this.expiryText)
            .concat(' renewalDate: ').concat(this.renewalDate)
            .concat(' valid: ').concat(this.valid)
    }
}

