import BaseModel from './BaseModel'
import ConnectType from '../Connect/ConnectionType';
import SubscriptionModel from './SubscriptionModel';

export default class PaymentModel extends BaseModel {

  static getInstance() {
    if(PaymentModel.instance === undefined) {
      PaymentModel.instance = new PaymentModel()
    }
    return PaymentModel.instance
  }

  constructor(props) {
    super(props)
  }

  populateData(data) {
    this.data = data
    this.dispatch(ConnectType.TYPE_PAYMENT_DATA_UPDATE, this.data)
  }

  clearData() {
    this.data = {}
  }

  
}