import React from 'react';

import ConnectionType from '../../../../Connect/ConnectionType'
import MessageControl from '../../../../Control/MessageControl';
import MessageModel from '../../../../Model/MessageModel';

import style from './taskItem.module.scss'
import theme from '../../../../Theme/theme.module.scss';
import moment from 'moment';

class TaskItem extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.onTaskItemPressed = this.onTaskItemPressed.bind(this);
    this.onTaskItemSelected = this.onTaskItemSelected.bind(this);
    this.onNewSocketData = this.onNewSocketData.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.getTitleStyle = this.getTitleStyle.bind(this);
    this.validateUnreadCheck = this.validateUnreadCheck.bind(this);

    let{id} = this.props.data;
    if(this.props.selectedTask){
      this.onTaskItemPressed();
    }

    this.state = {
      selected:id === MessageControl.getInstance().taskId || false,
      pendingMessage:this.validateUnreadCheck()
    }
  }

  validateUnreadCheck(){
     return (this.props.data.unread)
              ? true
              : MessageControl.getInstance().checkPendingMessage(this.props.data.id) || false

  }

  onTaskItemPressed(e){
    let {id} = this.props.data;
    this.setState({pendingMessage:false});
    MessageControl.getInstance().removePendingMessage(id);
    MessageControl.getInstance().getTask(id);
    MessageModel.getInstance().dispatch(ConnectionType.TYPE_TASK_TOGGLE_VIEW, {showChat: true});
    MessageModel.getInstance().updateUnreadState(id, false)

  }

  componentWillMount(){
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_TASK_SELECTED, this.onTaskItemSelected);
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_SOCKET_MESSAGE_DATA, this.onNewSocketData)
  }

  componentWillUnmount(){
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_SELECTED, this.onTaskItemSelected);
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_SOCKET_MESSAGE_DATA, this.onNewSocketData)
  }

  onNewSocketData(data){
    let {id} = this.props.data;
    if(data.id === id && !this.state.selected){
      MessageControl.getInstance().addPendingMessage(id);
      this.setState({pendingMessage:true})
    }
  }

  getStyle(){
    return (this.state.selected)
              ?  "TaskItemContainerSelected"
              : (this.state.pendingMessage)
                  ? "TaskItemPendingContainer"
                  : "TaskItemContainer";
  }

  getTitleStyle(){
    return (this.state.pendingMessage)
              ? "TaskItemTitlePending"
              : "TaskItemTitle"
  }

  onTaskItemSelected(taskId){
    let {id} = this.props.data;
    if(id === taskId && !this.state.selected){
      this.setState({
        selected:true,
        pendingMessage:false
      })
    }else if(this.state.selected){
      this.setState({selected:false})
    }
  }

  render(){

    let {title, createdAt, status, unread} = this.props.data;
    let date = moment(createdAt).format("DD");
    let format = moment(createdAt).format("MMM");

    return(
      <button className={`${style.taskCard} ${theme[this.props.theme]} ${ unread ? style.notificationContainer : "" } `}
			onClick={this.onTaskItemPressed}>
        <div className={`${style.taskCard__left}`}>
          <p className="taskCard__date fz-25 bold">{date}</p>
          <p className="taskCard__month fz-16 uppercase">{format}</p>
        </div>
        <div className={`${style.taskCard__right}`}>
          <p className="taskCard__title fz-16 bold">{title}</p>
          <p className="taskCard__status fz-14 mt-3">{status}</p>
        </div>
				<div className={`${ unread ? style.showText : style.hideText } `}>
				<span className={`${style.dot}`}></span>
				<p>NEW MESSAGES</p>
				</div>
        <div className={`${style.taskCard__arrow}`}></div>
      </button>
    )
  }
}

export default TaskItem;
