import React from 'react'
import HeyJudeUtils from '../../Utils/HeyJudeUtils';
import InputText from '../../Components/InputText';
// import '../SignIn.css';
import UserControl from '../../Control/UserControl';
import UserModel from '../../Model/UserModel';
import ConnectionType from '../../Connect/ConnectionType';

import theme from '../../Theme/theme.module.scss';
import style from './signup.module.scss';

import Picture from '../../Components/Picture';
import Button from '../../Components/Button';
import AlertMessage from '../../Components/Overlay/AlertMessage';


const logo = require('../../Assets/Img/appIcon.png');

var textStyle = {
  color: 'white',
  paddingTop: '30px',
  margin: '10px',
  WebkitTransition: 'all', // note the capital 'W' here
  msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

class SignUp extends React.Component {

    signUpParams =  {
        program:"prioritypet",
        promoCode: undefined,
        mobile: undefined,
        activationCode: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        passwordConfirmation: undefined,
        username: undefined,
    }

    mobileNum = undefined

    constructor(props) {
        super(props)

        this.inputPromo = React.createRef()

        this.codeSelector = React.createRef()
        this.fldMobile = React.createRef()

        this.inputOtp = React.createRef()
        this.fldEmail = React.createRef()
        this.fldPassword = React.createRef()
        this.fldPasswordConfirmation = React.createRef()
        this.fldFirstName = React.createRef()
        this.fldLastName = React.createRef()

        this.state = {
            needsPromoCode: true,
            dialingCodes: undefined,
            dialingCodeError: false,
            displayMobileInput: false,
            needsMobileValidation: false,
            hasSentOTP: false,
        }
    }

    componentDidMount(){
        this.getDialingCodes()
    }

    async getDialingCodes(){
        let response = await UserControl.getInstance().getDialingCodes()
        if(response.result){
           this.setState({
               dialingCodes:response.data,
               displayMobileInput: true
            })
        } else {
            this.setState({dialingCodeError: true})
        }
    }

    onBackPress() {
        if (this.state.hasSentOTP) {
            this.setState({
                needsPromoCode: false,
                needsMobileValidation: true,
                displayMobileInput: true,
                hasSentOTP: false,
            })
        } else if (this.state.needsMobileValidation) {
            this.setState({
                needsMobileValidation: false,
                needsPromoCode: true,
            })
        } else if (this.state.needsPromoCode) {
            let goBack = this.props.backToSignIn
            if (goBack) {
                goBack()
            }
        }
    }

    savePromoCode() {
        this.signUpParams.promoCode = this.inputPromo.getCurrentValue()
        this.setState({
            needsPromoCode: false,
            needsMobileValidation: true
        })
    }

    async validatePhoneNumber(){
        this.mobileNum = this.fldMobile.getCurrentValue()
        this.finalNumber = this.codeSelector.current.options[this.codeSelector.current.selectedIndex].value + "" + this.mobileNum
        let response = await UserControl.getInstance().verifyOTP(this.finalNumber)
        if (response.result) {
            this.onOtpSuccess()
        }
    }

    async resendOTP() {
        this.validatePhoneNumber()
    }

    async callOTP() {
        this.finalNumber = this.codeSelector.current.options[this.codeSelector.current.selectedIndex].value + "" + this.mobileNum
        let response = await UserControl.getInstance().verifyOTPWithCall(this.finalNumber)
        if (response.result) {
            this.onOtpSuccess()
        }
    }

    onOtpSuccess() {
        this.signUpParams.mobile = this.finalNumber

        this.setState({
            needsMobileValidation: false,
            displayMobileInput: false,
            hasSentOTP: true
        })
    }

    formValidates() {
        var result = true
        var msg = []

        let otp = this.inputOtp.getCurrentValue()
        if (otp === undefined || otp.length < 6) {
            msg.push("OTP must have 6 characters.")
            result = false
        }

        let email = this.fldEmail.getCurrentValue()
        if (email === undefined || !email.includes("@") || !email.includes(".")) {
            msg.push("Your email doesn't appear valid.")
            result = false
        }

        let password = this.fldPassword.getCurrentValue()
        let passwordConfirm = this.fldPasswordConfirmation.getCurrentValue()

        if (password == undefined || password.length < 6) {
            msg.push("Your password must have 6 or more characters.")
            result = false
        }

        if (password != passwordConfirm) {
            msg.push("Passwords don't match.")
            result = false
        }

        let firstName = this.fldFirstName.getCurrentValue()
        if(firstName === undefined || firstName.length === 0) {
            msg.push("Please enter a first name.")
        }

        if (!result) {
            AlertMessage.getInstance().show({
                show:true,
                title:'Validation Error',
                message:msg.join('\r\n'),
                acceptText:'Accept'
              })
        } else {
            this.signUpParams.activationCode = otp
            this.signUpParams.firstName = firstName
            this.signUpParams.lastName = this.fldLastName.getCurrentValue()
            this.signUpParams.email = email
            this.signUpParams.password = password
            this.signUpParams.passwordConfirmation = passwordConfirm
        }

        return result
    }

    async signUpUser() {
        if(!this.formValidates()) {
            return
        }

        UserControl.getInstance().signUp(this.signUpParams)

    }

    renderPromo() {
        if(!this.state.needsPromoCode) {
            return null
        }

        return (
            <div>
            <center><strong><p style={textStyle}>Sign Up</p></strong></center>
            <center><p style={textStyle}>If you have a promo code, please enter it below. If you don't have a code, leave it blank and continue.</p></center>
            <center><InputText
                className="mt-15 min-w-300"
                setReference={(ref) => this.inputPromo = ref}
                defaultValue={this.signUpParams.promoCode || ""}
                theme={this.props.theme}
                id="username"
                placeholder="Promo code (Optional)"
                type="text"
            /></center>
            </div>
        )
    }

    renderDialingCodes(dialingCodes){
        if (dialingCodes === undefined) {
            return (
                <option disabled selected>
                    {this.state.dialingCodeError ? "Please try again later" : "Loading"}
                </option>
            )
        }

        var displayList = [];

        for (var code of dialingCodes){
            var finalCode = "+" + code.phonecode;
            displayList.push(
                <option value={finalCode} key={code.phonecode}>{code.name}</option>
            )
        }
        displayList.reverse();

        return displayList;
    }

    renderMobileInput() {
        if(!this.state.displayMobileInput || this.state.needsPromoCode) {
            return null
        }

        return[
            <select key="codeSelect" className={`${style.FPSelect} mt-50 dropDown`} ref={this.codeSelector}>
                {this.renderDialingCodes(this.state.dialingCodes)}
            </select>,
            <InputText
                key="mobileInput"
                setReference={(ref) => this.fldMobile = ref}
                defaultValue={this.mobileNum || ""}
                theme={this.props.theme}
                id="username"
                placeholder="Mobile Number"
                type="tel"
                className="mt-15 min-w-300"
            />
        ]
    }

    renderForm() {
        if(!this.state.hasSentOTP) {
            return null
        }

        return [
            <InputText
                key="otpInput"
                className="mt-15 min-w-300"
                setReference={(ref) => this.inputOtp = ref}
                defaultValue={this.signUpParams.activationCode || ""}
                theme={this.props.theme}
                id="username"
                placeholder="OTP"
                type="text"
            />,
            <div className="wrapper wrapper_flex-center mt-30">
                <Button onClick={() => this.onButtonPress()} text="No OTP? Resend now" theme={this.props.theme} className="color-white fz-14"></Button>
            </div>,
            <InputText
                key="inputEmail"
                className="mt-15 min-w-300"
                setReference={(ref) => this.fldEmail = ref}
                defaultValue={this.signUpParams.email || ""}
                theme={this.props.theme}
                id="username"
                placeholder="Email"
                type="text"
            />,
            <InputText
                key="inputPassword"
                className="mt-15 min-w-300"
                setReference={(ref) => this.fldPassword = ref}
                defaultValue={this.signUpParams.password || ""}
                theme={this.props.theme}
                id="password"
                placeholder="Password"
                type="password"
            />,
            <InputText
                key="inputPasswordConfirm"
                className="mt-15 min-w-300"
                setReference={(ref) => this.fldPasswordConfirmation = ref}
                defaultValue={this.signUpParams.passwordConfirmation || ""}
                theme={this.props.theme}
                id="password"
                placeholder="Password confirmation"
                type="password"
            />,
            <InputText
                key="inputFirstName"
                className="mt-15 min-w-300"
                setReference={(ref) => this.fldFirstName = ref}
                defaultValue={this.signUpParams.firstName || ""}
                theme={this.props.theme}
                id="username"
                placeholder="First name"
                type="text"
            />,
            <InputText
                key="inputLastName"
                className="mt-15 min-w-300"
                setReference={(ref) => this.fldLastName = ref}
                defaultValue={this.signUpParams.lastName || ""}
                theme={this.props.theme}
                id="username"
                placeholder="Last name"
                type="text"
            />,
        ]
    }

    render() {
        return (
            <div className={`${theme.default} wrapper wrapper_page wrapper-sign ${theme[this.props.theme]}`}>
                <div className={`wrapper wrapper_flex-center min-h-100vh pt-30 pb-30`}>
                    <div className="wrapper wrapper_flex-column-center">
                    <center><img src="https://www.heyjudeapp.com/img/logo-nav-light.svg" alt="Logo" width="200"/></center>
                        {this.renderPromo()}
                        {this.renderMobileInput()}
                        {this.renderForm()}
                        <div className="wrapper wrapper_flex-center mt-30">
                            <Button onClick={() => this.onButtonPress()} text={this.getButtonLabel()} theme={this.props.theme} className="fz-14"></Button>
                        </div>
                    </div>
                </div>
                <div style={{position:'absolute', top:20, left:20}}>
                    <Button onClick={() => this.onBackPress()} textonly text="Back" theme={this.props.theme} className="color-white fz-14"></Button>
                </div>
            </div>
        )
    }

    getButtonLabel() {
        if (this.state.needsPromoCode) {
            return "Continue"
        } else if (this.state.needsMobileValidation) {
            return "Validate Number"
        } else if(this.state.hasSentOTP) {
            return "Sign Up"
        }
    }

    onButtonPress() {
        if (this.state.needsPromoCode){
            this.savePromoCode()
        } else if (this.state.needsMobileValidation) {
            this.validatePhoneNumber()
        } else {
            this.signUpUser()
        }
    }
}

export default SignUp;
