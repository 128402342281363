import React from 'react';
import style from './button.module.scss';
import theme from '../../Theme/theme.module.scss';



class Button extends React.PureComponent{
    static defaultProps = {
        theme: 'default'
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){
        return(
            <button
                onClick={this.props.onClick}
                id={(this.props.id) ? this.props.id : ''}
                className={`
                    ${style.button}
                    ${theme[this.props.theme]}
                    ${this.props.className}
                    ${(this.props.textonly) ? style.textonly : ''}
										${(this.props.textonly_small) ? style.textonly_small : ''}
                    ${(this.props.empty) ? style.empty : ''}
                    ${(this.props.hasOwnProperty('active')) ? (this.props.active) ? 'button-active' : 'button-inactive' : ''}
                    ${(this.props.secondary) ? 'button-secondary' : ''}
                `}>

                {(this.props.content) ? this.props.content : this.props.text}
            </button>
        );
    }
}

export default Button;
