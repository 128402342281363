import React from 'react';
import './AlertLoader.scss';
import Picture from '../../Components/Picture/index';
import theme from '../../Theme/theme.module.scss';



class AlertLoader extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  static setInstance(instance){
    AlertLoader.instance = instance;
  }

  static getInstance(instance){
    return AlertLoader.instance;
  }


  constructor(props){
    super(props);

    this.state={
      show:false,
      message:'',
    }

    this.show = this.show.bind(this)

    AlertLoader.setInstance(this);
  }

  show(options){
    this.setState ({
      ...options
    })
  }

  render(){
    if(!this.state.show){
      return null;
    }

    return(
      <div className="AlertLoaderPage">
        <div className="AlertLoaderContainer">
          <br/><br/>
          <div className="AlertLoaderText">{this.state.message}</div>
        </div>
      </div>
    )
  }


}

export default AlertLoader;
