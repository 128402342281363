import React from 'react';
import './MMenu.scss';
import MModalButton from '../MModalButton';

class MMenu extends React.PureComponent{

	constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="MMenu_Hey">
      <a className="legals" href="https://www.heyjudeapp.com/terms" target="_blank"><strong>Terms and Conditions</strong></a><br/><br/>
			<a className="legals" href="https://www.heyjudeapp.com/privacy" target="_blank"><strong>Data and Privacy Policy</strong></a><br/><br/>
			</div>
    );
  }

}

export default MMenu;
