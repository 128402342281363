
// React-Native and Socket.io gotcha -> is that window.navigator.userAgent doesn't exist. 
// Socket.IO checks to deal with some browser incosistencies because the
// debugger in React Native runs in a webworker and only has a getter method for
// `window.navigator`. So all we have to do is create it. 
import io from 'socket.io-client';
import axios from 'axios';
import { ApiHelper } from '../Helpers/ApiHelper';
import { User as UserApis } from '../User'
import { User, Message } from '../Models/';
import HeyJudeUtils from "../../Utils/HeyJudeUtils";

//window.navigator.userAgent = "react-native";


export default class Auth extends ApiHelper {

    // onSocketDataCallBack = undefined

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
        this.user = null;
        this.socket = null;
        this.chatStatus = null;

        this.onSocketData = this.onSocketData.bind(this)
        // this.unBindChat = this.unBindChat.bind(this)
        
    }

    printEnvironment() {
        console.log('Environment', super.getEnvironment());
    }

    /**
    * This method attempts to sign a user in.
    * Aswell as setting up the connection to socket.io for recieving chat messages
    * @param {Object} details Object containing the required values
    * @param {String} details.authKey
    * @param {String} details.realityId
    * @param {String} details.platform
    * @param {String} details.password
    * @param {String} details.username
    *
    * Demo Details object.
    *
    * var details = {
    *
    *        authKey: "1234567890",
    *
    *        realityId: "2107152929"
    *
    *        platform: "ios",
    *
    *        password: "ssssss",
    *
    *        username: "username",
    *
    * }
    * @returns {Promise} promise
    * @returns {User} (resolve) User object.
    * @returns (reject) Array of errors.
    */
    signIn = (details) => {
        var self = this;
        var baseURL = super.getBaseURL();
        return new Promise((resolve, reject) => {
            super.post('auth/sign-in', {
                "auth_key": details.authKey ? details.authKey : "",
                "reality_id": details.realityId ? details.realityId : "",
                "platform": "web", //details.platform ? details.platform : "",
                "program": super.getProgram(),
                "password": details.password ? details.password : "",
                "email": details.username ? details.username : "",
                "username": details.username ? details.username : "",
            }, function (data) {
                self.user = new User(data.user);
                self.initiateSocket();
                resolve(self.user);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * This method attempts to sign a user in.
     * Aswell as setting up the connection to socket.io for recieving chat messages
     * @param {Object} details Object containing the required values
     * @param {String} details.authKey
     * @param {String} details.realityId
     * @param {String} details.platform
     * @param {String} details.password
     * @param {String} details.username
     *
     * Demo Details object.
     *
     * var details = {
     *    token: "test123",
     * }
     * @returns {Promise} promise
     * @returns {User} (resolve) User object.
     * @returns (reject) Array of errors.
     */
    tokenSignIn = (details) => {
        let judeMgr = HeyJudeUtils.JudeManager();
        let self = this;
        let baseURL = super.getBaseURL();
        return new Promise((resolve, reject) => {
            super.post(baseURL + '/api/v1/auth/token-auth', {
                "auth_key": details.token ? details.token : "",
                "app": judeMgr.User.program ? judeMgr.User.program : ""
            }, function (data) {
                data.user.payment_methods = data.payment_methods;
                self.user = new User(data.user);
                self.initiateSocket();
                resolve(self.user);
            }, function (data) {
                reject(data);
            });
        });
    };

    initiateSocket() {
        // Called from signin, signup and resume from token
        var baseURL = super.getBaseURL();
        var userId = this.user.id;
        this.socket = io(baseURL, {
            path: '/chat',
            query: 'user_id='.concat(userId).concat('&token=').concat(this.getToken()),
            transports: ['websocket']
        }).connect();
        try {
            this.sendAnalytics();
            // var deviceInfo = getDeviceInfo();
            // if (deviceInfo.deviceManufacturer) {
            //     this.sendAnalytics(getDeviceInfo());
            // }
        } catch (ex) {
            // Do nothing
        }

    }

    /** 
    * This function connects to the chat channel, 
    * recieves messages then returns the message through the callback function
    * @param {Function} callback
    */
    bindToChat(callback) {
        this.onSocketDataCallBack = callback
        var userId = this.user.id;
        this.socket.on('chat-channel:' + userId, this.onSocketData);
    }

    onSocketData(data) {
        var message = JSON.parse(data);
        this.onSocketDataCallBack(new Message(message));
    }

    /**
     * 
     * @param {Function} callback callback fuction is called when chat status changes
     * @returns {String} status as a string eg. 'connected' or 'disconnected'
     */
    bindToChatStatus = (callback) => {
        
        this.socket.on('connect', () => {
            this.chatStatus = 'disconnected';
            callback('disconnected');
        });

        this.socket.on('disconnect', () => {
            this.chatStatus = 'disconnected';
            callback('disconnected');
        });
    }

    unBindChat() {
        let userId = this.user.id
        this.socket.off('connect')
        this.socket.off('disconnect')
        this.socket.off('chat-channel:' + userId, this.onSocketData)
    }


    /**
    * This method attempts to sign a user up.
    * @param {Object} details Object containing the required values
    * 
    * 
    * Demo details object.
    * 
    * var details = {
    * 
    *        passwordConfirmation: "ssssss", 
    * 
    *        mobile: "+277123456789", (required)
    * 
    *        longitude: "",
    * 
    *        latitude: "",
    * 
    *        password: "ssssss", (required)
    * 
    *        firstName: "Foo", (required)
    * 
    *        lastName: "Bar", (required)
    * 
    *        platform: "ios", (required)
    * 
    *        deviceToken: "device_token",
    * 
    *        promoCode: "",
    * 
    *        email: "email address", (required)
    * 
    *        activationCode: "123456", (required)
    * 
    *        referralCode: "hey promo"
    * 
    * }
    * @returns {Promise} promise
    * @returns {User} (resolve) User object.
    * @returns (reject) Array of errors.
    */
    signUp(details) {
        var self = this;
        return new Promise((resolve, reject) => {
            if (details) {
                super.post('auth/sign-up', {
                    "password_confirmation": details.passwordConfirmation ? details.passwordConfirmation : "",
                    "mobile": details.mobile ? details.mobile : "",
                    "longitude": details.longitude ? details.longitude : "",
                    "latitude": details.latitude ? details.latitude : "",
                    "password": details.password ? details.password : "",
                    "first_name": details.firstName ? details.firstName : "",
                    "platform": 'web',
                    //"platform": details.platform ? details.platform : "",
                    "device_token": details.deviceToken ? details.deviceToken : "",
                    "promo_code": details.promoCode ? details.promoCode : "",
                    "program": super.getProgram(),
                    "last_name": details.lastName ? details.lastName : "",
                    "email": details.email ? details.email : "",
                    // "activation_code": details.activationCode,
                    "activation_code": details.activationCode ? details.activationCode : "",
                    "referral_code": details.referralCode ? details.referralCode : "",
                }, function (data) {
                    self.user = new User(data.user);
                    resolve(self.user);
                }, function (data) {
                    console.log('Sign up failed');
                    console.log(data);
                    reject(data);
                });
            } else {
                reject('invalid object');
            }
        });
    }

    /**
    * Signs the user out.
    * @returns {Promise} promise
    */
    signOut() {
        this.unBindChat()
        this.socket.close()
        this.socket.disconnect()

        return new Promise((resolve, reject) => {
            super.get('auth/sign-out', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     *  Attempts to refresh the jwtToken
     * @returns {Promise} promise
     */
    refresh() {
        return new Promise((resolve, reject) => {
            super.get('auth/refresh', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * @param {String} phone number(msisdn)
    * @returns {Promise} promise
    */
    verifyPhoneNumber(phoneNumber) {
        return new Promise((resolve, reject) => {
            super.post('auth/verify', {
                "program": super.getProgram(),
                "type": "sms",
                "mobile": phoneNumber
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    verifyPhoneNumberWithCall(phoneNumber) {
        return new Promise((resolve, reject) => {
            super.post('auth/verify', {
                "program": super.getProgram(),
                "type": "call",
                "mobile": phoneNumber
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    // There are problems here sometimes works other times not
    /**
    * @param {String} phone number(msisdn)
    * @returns {Promise} promise
    */
    forgotPassword(phoneNumber) {
        return new Promise((resolve, reject) => {
            super.post('auth/forgot', {
                "program": super.getProgram(),
                "type": "sms",
                "mobile": phoneNumber
            }, function (data) {
                console.log(data);
                resolve("You will receive your reset password code shortly.");
            }, function (data) {
                console.log('Error Data in forgot password', data);
                reject(data);
            });
        });
    }

    /**
    * This method resets the users password 
    * @param {String} resetCode The reset code sms'ed to the user
    * @param {String} password New password set by the user
    * @param {String} phoneNumber User's phone number(msisdn)
    * @returns {Promise} promise
    */
    reset(resetCode, password, phoneNumber) {
        var self = this;
        return new Promise((resolve, reject) => {
            super.post('auth/reset', {
                "password": password,
                "mobile": phoneNumber,
                "program": super.getProgram(),
                "reset_code": resetCode,
                "password_confirmation": password
            }, function (data) {
                self.user = new User(data.user);
                resolve(self.user);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Sends device device info analytics to hey jude so that we can improve our system for you. 
     * 
     * @param {Object} deviceInfo 
     * @param {String} deviceInfo.deviceManufacturer eg. Apple
     * @param {String} deviceInfo.deviceOsVersion
     * @param {String} deviceInfo.appVersion
     * @param {String} deviceInfo.longitude
     * @param {String} deviceInfo.deviceIdentifier
     * @param {Boolean} deviceInfo.locationEnabled
     * @param {String} deviceInfo.deviceScreenSize
     * @param {String} deviceInfo.latitude
     * @param {String} deviceInfo.deviceToken
     * @param {String} deviceInfo.deviceOs
     * @param {Boolean} deviceInfo.pushNotificationsEnabled
     * @param {String} deviceInfo.deviceModel
     * @param {String} deviceInfo.deviceCarrier
     * fired from token refresh
     */
    sendAnalytics() {
        let allParams = {
            device_os:"web",
            device_identifier:"web",
            device_manufacturer: 'web',
            device_model: 'web',
            device_os_version: 'web',
            app_version: '1.0.0',
            app_push_enabled: 'false',
            app_location_enabled: 'false',
        }

        return new Promise((resolve, reject) => {
            super.post('analytics', allParams,
            function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }
}

